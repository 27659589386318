import { zodResolver } from "@hookform/resolvers/zod";
import {
  formSchema,
  FormSchema,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/formSchema";
import { FC } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useStretchBuilder } from "stores/useStretchBuilder";
import { ActionModal } from "shared/components/ActionModal";
import { InfrastructureModalContent } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/InfrastructureModalContent";
import { getStretchName } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stretchBuilderTexts";
import { usePostInfrastructureEvent } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/hooks/usePostInfrastructureEvent";
import { useOpenEditFields } from "stores/useOpenEditFields";
import { getInfrastructureEventBody } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/createRequestUtils";
import {
  defaultValuesCreate,
  getDefaultValuesEdit,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/infrastructureEventDefaultValues";

export const InfrastructureEvent: FC = () => {
  const [
    clickedStops,
    selectedLegs,
    allStopsSelected,
    stretchBuilderModalState,
    closeAndResetStretchBuilder,
  ] = useStretchBuilder((state) => [
    state.clickedStops,
    state.selectedLegs,
    state.allStopsSelected,
    state.stretchBuilderModalState,
    state.closeAndResetStretchBuilder,
  ]);

  const event =
    (stretchBuilderModalState.type === "infrastructure" &&
      stretchBuilderModalState.eventInfo) ||
    undefined;

  const isEditMode = !!event;

  const defaultValues = isEditMode
    ? getDefaultValuesEdit(event)
    : defaultValuesCreate;

  const formMethods = useForm<FormSchema>({
    resolver: zodResolver(formSchema),
    defaultValues,
  });

  const {
    mutate: postInfraInfo,
    isPending: isPostRequestPending,
    isSuccess: isPostRequestSuccess,
    isError: isPostRequestError,
  } = usePostInfrastructureEvent(event?.uuid);

  const { handleSubmit } = formMethods;

  const openEditFields = useOpenEditFields((state) => state.openEditFields);

  const onSubmit = handleSubmit((data) => {
    const requestBody = getInfrastructureEventBody(data.infrastructureForm);
    postInfraInfo(requestBody);
  });

  if (stretchBuilderModalState.type !== "infrastructure") return null;

  return (
    <FormProvider {...formMethods}>
      {!stretchBuilderModalState.stretchBuilderMode ? (
        <ActionModal
          title={`${isEditMode ? "Endre" : "Opprett"} infrastrukturhendelse`}
          actionTitle={`${isEditMode ? "Endre" : "Opprett"} hendelse`}
          onClose={closeAndResetStretchBuilder}
          onSubmit={onSubmit}
          isLoading={isPostRequestPending}
          isSuccess={isPostRequestSuccess}
          isError={isPostRequestError}
          isSubmitDisabled={openEditFields.length > 0}
          successMessage={`Hendelse ${isEditMode ? "endret" : "opprettet"}`}
          failureMessage={`Kunne ikke ${isEditMode ? "endre" : "opprette"} hendelse. Prøv på nytt, eller kontakt IT hvis feilen vedvarer.`}
        >
          <InfrastructureModalContent
            stretchTitle={
              clickedStops.length < 1 && isEditMode
                ? event.stretchName
                : getStretchName(
                    selectedLegs,
                    clickedStops,
                    allStopsSelected,
                  ).join(", ")
            }
          />
        </ActionModal>
      ) : (
        <div />
      )}
    </FormProvider>
  );
};
