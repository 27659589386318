import {
  InfiniteData,
  useInfiniteQuery,
  useMutation,
} from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { mutationFnPOST, queryFnGET } from "api/tanStackQuery/helpers";
import { queryClient } from "api/tanStackQuery/queryClient";
import { useFeatureFlag } from "app/FeatureFlags/useFeatureFlags";
import constate from "constate";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { UserRole } from "shared/types/roles";
import { useDropsWebsocket } from "websocket/drops/DropsWebsocketContext";
import { OploggForm, TodoForm } from "../formSchema";
import { DropsLogEntry, DropsLogResponse } from "./OploggElement/types";
import { getDropsLogPath, updatePages } from "./OploggElement/utils";

export const dropsLogQueryKey = (
  useDropsLogV2: boolean,
  selectedDateTime: string | null,
) => [useDropsLogV2 ? "dropsLogV2" : "dropsLog", selectedDateTime];

function useOplogg() {
  const useDropsLogV2 = useFeatureFlag("dropsLogV2").enabled;

  const [notifications, setNotifications] = useState(0);
  const [selectedDateTime, setSelectedDateTime] = useState<string | null>(null);
  const { message } = useDropsWebsocket("useDropsLog", ["DROPS_LOG"]);
  const [showRoles, setShowRoles] = useState(false);
  const [showVehicleSets, setShowVehicleSets] = useState(false);

  const addMessageToPages = (newMessage: DropsLogEntry) => {
    queryClient.setQueryData<InfiniteData<DropsLogResponse> | undefined>(
      dropsLogQueryKey(useDropsLogV2, null),
      (prev) =>
        prev && {
          ...prev,
          pages: updatePages(prev.pages, newMessage, useDropsLogV2),
        },
    );
  };

  const { mutate: post, status: postStatus } = useMutation({
    mutationFn: (data: OploggForm) =>
      mutationFnPOST<DropsLogEntry | null, OploggForm>(
        `${getBackendUrl()}/${getDropsLogPath(useDropsLogV2)}/logs`,
        data,
      ),
    onSuccess: (entry) => {
      if (entry) addMessageToPages(entry);
    },
  });

  const { mutate: postToDo } = useMutation({
    mutationFn: (data: TodoForm) =>
      mutationFnPOST<undefined, TodoForm>(`${getBackendUrl()}/todo`, data),
  });

  const PAGINATION_LIMIT = selectedDateTime ? 5000 : 20; // if selectedDateTime is set, we want to fetch more logs

  const TIME_ZONE_OFFSET = useDropsLogV2 ? 0 : 1;
  const TIME_INTERVAL_HOURS = 35 / 60;
  const HOUR_MULTIPLIER = 60 * 60 * 1000;
  const START_TIME_MODIFIER =
    (TIME_ZONE_OFFSET + TIME_INTERVAL_HOURS) * HOUR_MULTIPLIER;
  const END_TIME_MODIFIER =
    (TIME_ZONE_OFFSET - TIME_INTERVAL_HOURS) * HOUR_MULTIPLIER;

  // Set endDateTime to the end of the day if selectedDateTime is present
  const startDateTime = selectedDateTime
    ? new Date(new Date(selectedDateTime).getTime() + END_TIME_MODIFIER)
    : null;
  const dynamoStartDateTime = startDateTime
    ? startDateTime.toISOString().slice(0, -1)
    : null;
  const postgresStartDateTime = startDateTime
    ? format(startDateTime, "yyyy-MM-dd'T'HH:mm:ss")
    : null;
  const endDateTime = selectedDateTime
    ? new Date(new Date(selectedDateTime).getTime() + START_TIME_MODIFIER)
    : null;
  const dynamoEndDateTime = endDateTime
    ? endDateTime.toISOString().slice(0, -1)
    : null;
  const postgresEndDateTime = endDateTime
    ? format(endDateTime, "yyyy-MM-dd'T'HH:mm:ss")
    : null;

  const {
    data: _data,
    hasNextPage,
    status,
    isFetchingNextPage,
    fetchNextPage,
  } = useInfiniteQuery({
    queryKey: dropsLogQueryKey(useDropsLogV2, selectedDateTime),
    initialPageParam: 0,
    queryFn: ({ signal, pageParam }) =>
      queryFnGET<DropsLogResponse>({
        signal,
        url: `${getBackendUrl()}/${getDropsLogPath(useDropsLogV2)}/logs?pageSize=${PAGINATION_LIMIT}${
          pageParam > 0 ? `&lastSeen=${pageParam}&pageOffset=${pageParam}` : "" // TODO: DynamoDB - Remove lastSeen
        }${selectedDateTime ? `&startDateTime=${dynamoStartDateTime}&minDateTime=${postgresStartDateTime}` : ""}${
          // TODO: DynamoDB - Remove startDateTime
          endDateTime
            ? `&endDateTime=${dynamoEndDateTime}&maxDateTime=${postgresEndDateTime}`
            : "" // TODO: DynamoDB - Remove endDateTime
        }`,
      }),
    getNextPageParam: (lastPage) =>
      lastPage.hasNext ? lastPage.lastSeen : null,
    refetchOnWindowFocus: true,
  });

  const data = _data
    ? {
        ..._data,
        pages: _data.pages.map((page) => ({
          ...page,
          entries: page.entries.map((entry) => ({
            ...entry,
            readReceipts: [
              // TODO: Remove this mock data when backend is implemented
              UserRole.vaktleder,
              UserRole.personellstyrerkond,
              UserRole.lokleder1,
            ],
          })),
        })),
      }
    : undefined;

  useEffect(() => {
    if (
      message.status === "received" &&
      message.data.topic === (useDropsLogV2 ? "DROPS_LOG_V2" : "DROPS_LOG")
    ) {
      setNotifications(notifications + 1);
      const newMessage: DropsLogEntry = {
        ...message.data.message,
        readReceipts: [
          // TODO: Remove this mock data when backend is implemented
          UserRole.vaktleder,
          UserRole.personellstyrerkond,
          UserRole.lokleder1,
        ],
      };
      addMessageToPages(newMessage);
    }
  }, [message]);
  return {
    post,
    postStatus,
    postToDo,
    setSelectedDateTime,
    data,
    status,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    notifications,
    setNotifications,
    showRoles,
    setShowRoles,
    showVehicleSets,
    setShowVehicleSets,
  };
}

const [
  OploggProvider,
  usePost,
  usePostStatus,
  usePostTodo,
  useDate,
  useData,
  useStatus,
  useHasNextPage,
  useIsFetchingNextPage,
  useFetchNextPage,
  useNotifications,
  useSetNotifications,
  useShowRoles,
  useSetShowRoles,
  useShowVehicleSets,
  useSetShowVehicleSets,
] = constate(
  useOplogg,
  (value) => value.post,
  (value) => value.postStatus,
  (value) => value.postToDo,
  (value) => value.setSelectedDateTime,
  (value) => value.data,
  (value) => value.status,
  (value) => value.hasNextPage,
  (value) => value.isFetchingNextPage,
  (value) => value.fetchNextPage,
  (value) => value.notifications,
  (value) => value.setNotifications,
  (value) => value.showRoles,
  (value) => value.setShowRoles,
  (value) => value.showVehicleSets,
  (value) => value.setShowVehicleSets,
);

export {
  OploggProvider,
  useData,
  useDate,
  useFetchNextPage,
  useHasNextPage,
  useIsFetchingNextPage,
  useNotifications,
  usePost,
  usePostStatus,
  usePostTodo,
  useSetNotifications,
  useSetShowRoles,
  useSetShowVehicleSets,
  useShowRoles,
  useShowVehicleSets,
  useStatus,
};
