/* eslint-disable no-prototype-builtins */

export const getFieldFromNestedObject = <V>(
  obj: object | undefined,
  path: string,
): V | undefined => {
  if (!obj) {
    return undefined;
  }

  const [first, ...rest] = path.split(".");

  if (rest.length === 0) {
    if (!obj.hasOwnProperty(first)) {
      return undefined;
    }

    return obj[first as keyof object] as V;
  }

  if (!obj.hasOwnProperty(first)) {
    return undefined;
  }

  return getFieldFromNestedObject(obj[first as keyof object], rest.join("."));
};

const snakeCase = (str: string) => str.replace(/([A-Z])/g, "_$1").toLowerCase();

type CamelToSnake<S extends string> = S extends `${infer T}${infer U}`
  ? `${T extends Capitalize<T> ? "_" : ""}${Lowercase<T>}${CamelToSnake<U>}`
  : S;

type KeysToSnakeCase<T> = {
  [K in keyof T as CamelToSnake<string & K>]: T[K];
};

export const keysSnakeCase = <T extends Record<string, any>>(
  obj: T,
): KeysToSnakeCase<T> =>
  Object.keys(obj).reduce((acc, key) => {
    const snakeKey = snakeCase(key) as keyof KeysToSnakeCase<T>; // Convert to snake_case and assert type
    acc[snakeKey] = obj[key]; // Use type assertion to safely assign the value
    return acc;
  }, {} as KeysToSnakeCase<T>);
