import { ButtonGroup, Flex, HStack, Text } from "@vygruppen/spor-react";
import { ChoiceChipForInfoBackground } from "features/CenterContent/RoleContent/EventInteraction/ChoiceChipForInfoBackground";
import {
  EventButton,
  EventTypeButton,
} from "features/CenterContent/RoleContent/EventInteraction/EventButton";
import { StretchBuilderModalState } from "features/CenterContent/RoleContent/EventInteraction/StretchBuilderModalState";
import { StretchNameBadges } from "features/CenterContent/RoleContent/EventInteraction/StretchNameBadges";
import {
  getClickedStopPairs,
  getStretchName,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stretchBuilderTexts";
import { FC } from "react";
import { useStretchBuilder } from "stores/useStretchBuilder";
import { useTheme } from "styled-components";

export const EventInteractionBar: FC = () => {
  const theme = useTheme();

  const [
    clickedStops,
    selectedLegs,
    selectedStops,
    allStopsSelected,
    setAllStopsSelected,
    stretchBuilderModalState,
    setStretchBuilderModalState,
    closeAndResetStretchBuilder,
  ] = useStretchBuilder((state) => [
    state.clickedStops,
    state.selectedLegs,
    state.selectedStops,
    state.allStopsSelected,
    state.setAllStopsSelected,
    state.stretchBuilderModalState,
    state.setStretchBuilderModalState,
    state.closeAndResetStretchBuilder,
  ]);

  const { singleClickedStop } = getClickedStopPairs(clickedStops);

  const isEditStation =
    stretchBuilderModalState.type === "station" &&
    !!stretchBuilderModalState.eventInfo;
  const isEditInfrastructure =
    stretchBuilderModalState.type === "infrastructure" &&
    !!stretchBuilderModalState.eventInfo;
  const disableInfrastructureButton =
    isEditStation || selectedLegs.length === 0 || singleClickedStop !== null;
  const disableStationButton =
    isEditInfrastructure || selectedStops.length === 0;
  const disableGlobalButton = selectedStops.length > 0;

  const eventTypeButtons: EventTypeButton[] = [
    {
      title: "Infrastruktur",
      isDisabled: disableInfrastructureButton,
      onClick: () => {
        if (stretchBuilderModalState.type === "infrastructure") {
          // if type is already "infrastructure", it means edit mode
          setStretchBuilderModalState({
            ...stretchBuilderModalState,
            stretchBuilderMode: false,
          });
        } else {
          setStretchBuilderModalState({
            type: "infrastructure",
            stretchBuilderMode: false,
          });
        }
      },
    },
    {
      title: "Stasjon",
      isDisabled: disableStationButton,
      onClick: () => {
        if (stretchBuilderModalState.type === "station") {
          setStretchBuilderModalState({
            ...stretchBuilderModalState,
            stretchBuilderMode: false,
          });
        } else {
          setStretchBuilderModalState({
            type: "station",
            stretchBuilderMode: false,
          });
        }
      },
    },
    /* {
      title: "Global driftsmelding",
      isDisabled: disableGlobalButton,
      onClick: () => {
        setStretchBuilderModalState({
          type: "none",
        });
      },
    }, */
  ];

  const stretchNames = getStretchName(
    selectedLegs,
    clickedStops,
    allStopsSelected,
  );

  return (
    <>
      <Flex
        backgroundColor={theme.colorInfoFill}
        color={theme.colorInfoTextMain}
        justifyContent="stretch"
        paddingY="12px"
        paddingX="24px"
        marginY="6px"
        borderRadius="6px"
      >
        <HStack width="100%">
          <Text variant="xs" fontWeight="bold" width="fit-content">
            {isEditInfrastructure || isEditStation ? "Endre" : "Opprett"}{" "}
            hendelse
          </Text>
          <Text variant="xs" width="fit-content">
            For område
          </Text>
          <ChoiceChipForInfoBackground
            size="xs"
            isChecked={allStopsSelected}
            onChange={() => setAllStopsSelected(!allStopsSelected)}
          >
            Hele landet
          </ChoiceChipForInfoBackground>
          <StretchNameBadges
            stretchNames={stretchNames}
            singleClickedStop={singleClickedStop}
          />
          <Text variant="xs" minWidth="fit-content">
            og/eller type avvik
          </Text>
          <ButtonGroup>
            {eventTypeButtons.map((button) => (
              <EventButton button={button} key={button.title} />
            ))}
          </ButtonGroup>
        </HStack>
        <HStack>
          <EventButton
            button={{
              title: "Avbryt",
              isDisabled: false,
              onClick: () => closeAndResetStretchBuilder(),
            }}
            cancelIcon
          />
        </HStack>
      </Flex>
      <StretchBuilderModalState />
    </>
  );
};
