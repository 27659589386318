import { Tooltip } from "@chakra-ui/react";
import {
  Button,
  ButtonGroup,
  Divider,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Stack,
  StaticAlert,
} from "@vygruppen/spor-react";
import { FormEvent, useEffect, useRef } from "react";
import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import { FCWC } from "shared/utils/FCWC";
import styled from "styled-components";

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const ModalScrollableBody = styled(ModalBody)`
  ${ObtrusiveScrollCss};
  overflow-y: auto;
  max-height: 75vh;
`;

type ActionModalProps = {
  title: string;
  actionTitle: string;
  cancelTitle?: string;
  onClose: () => void;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  // ↓ If enabled then submit is clickable when disabled, but runs this function instead
  onSubmitDisabled?: (e: FormEvent<HTMLFormElement>) => void;
  isSubmitDisabled?: boolean;
  onSubmitTooltip?: string;
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  successMessage: string;
  failureMessage: string;
};

export const ActionModal: FCWC<ActionModalProps> = ({
  title,
  actionTitle,
  cancelTitle,
  onClose,
  onSubmit,
  onSubmitDisabled,
  isSubmitDisabled = false,
  onSubmitTooltip,
  isLoading,
  isSuccess,
  isError,
  successMessage,
  failureMessage,
  children,
}) => {
  useEffect(() => {
    if (isSuccess) {
      setTimeout(onClose, 1000);
    }
  }, [isSuccess]);

  const scrollRef = useRef<HTMLDivElement | null>(null);
  const scrollToBottom = () =>
    scrollRef.current?.scrollBy({
      top: 10000,
      behavior: "smooth",
    });
  function isInfoboxVisible() {
    const container = scrollRef.current;
    const infobox = document.getElementById("infoBoxActionModal");
    if (!container || !infobox) return true;
    const containerRect = container.getBoundingClientRect();
    const infoboxRect = infobox.getBoundingClientRect();

    const isWithinVerticalBounds =
      infoboxRect.top >= containerRect.top &&
      infoboxRect.bottom <= containerRect.bottom + 50; // 50px extra leeway

    return isWithinVerticalBounds;
  }

  return (
    <Modal isOpen onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader pt={5} pb={3} style={{ textAlign: "left" }}>
          {title}
        </ModalHeader>
        <Divider w="100%" />
        <ModalCloseButton />
        <ModalScrollableBody ref={scrollRef} pt="20px">
          <StyledForm
            onSubmit={(e) => {
              if (isSubmitDisabled && onSubmitDisabled) {
                onSubmitDisabled(e);
                e.nativeEvent.preventDefault();
                return;
              }
              onSubmit(e);
            }}
            id={`${title}-form`}
          >
            <Stack alignItems="center">{children}</Stack>
          </StyledForm>
        </ModalScrollableBody>
        <ModalFooter
          justifyContent="space-between"
          width="100%"
          pb={4}
          flexDir="column"
        >
          <Divider w="calc(100% + 36px)" mb="10px" />
          {isError && (
            <StaticAlert w="100%" variant="error">
              {failureMessage}
            </StaticAlert>
          )}
          {isSuccess && (
            <StaticAlert
              w="100%"
              variant="success"
              data-testid={`${title}-success-msg`}
            >
              {successMessage}
            </StaticAlert>
          )}
          <ButtonGroup justifyContent="space-between" mt={2} w="100%">
            <Button
              type="button"
              variant="tertiary"
              onClick={(e) => {
                e.preventDefault();
                onClose();
              }}
            >
              {cancelTitle || "Avbryt"}
            </Button>
            <Tooltip label={onSubmitTooltip}>
              <Button
                aria-invalid={isSubmitDisabled}
                form={`${title}-form`}
                isDisabled={
                  isSuccess || (isSubmitDisabled && !onSubmitDisabled)
                }
                variant="primary"
                onClick={(e) => {
                  if (isInfoboxVisible()) return;
                  scrollToBottom();
                  e.preventDefault();
                }}
                type="submit"
                isLoading={isLoading}
              >
                {isError ? "Prøv igjen" : actionTitle}
              </Button>
            </Tooltip>
          </ButtonGroup>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};
