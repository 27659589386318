import { Stack } from "@vygruppen/spor-react";
import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { OpLoggInput } from "./OploggInput";
import OploggMessages from "./OploggMessages";

const Oplogg = () => (
  <Stack flexDir="column" overflowY="auto" w="420px">
    <OpLoggInput />
    <OploggMessages />
  </Stack>
);

export default withErrorBoundary(Oplogg);
