import { Button, ButtonGroup, Flex } from "@vygruppen/spor-react";
import { LogLevel, log } from "api/cloudWatch";
import { getLocalStorageUsername } from "api/dropsLocalStorage";
import { Dispatch, SetStateAction, useState } from "react";
import { RadioButton } from "shared/components/forms/RadioButton";
import { ROLE_TEXTS } from "shared/types/roles";
import { TodoRowContainer } from "../../TodoStyles";
import { ErrorTextDescriptionElement } from "../../common/ErrorMessage";
import TextInput from "../../common/TextInput";
import { updateExistingTodo } from "../todoListApi";
import { Todo } from "./TodoElement";
import useForm, { UseFormReturnType } from "./useForm";

interface ITodoEdit {
  todo: Todo;
  setSelectedTodoId: Dispatch<SetStateAction<string>>;
  selectedTodoId: string;
}

const TodoEdit = (props: ITodoEdit) => {
  const userName = getLocalStorageUsername();
  const { setSelectedTodoId, selectedTodoId, todo } = props;

  const [showError, setShowError] = useState(false);

  const { inputs, handleInputChange, handleSubmit }: UseFormReturnType =
    useForm(() => {
      updateExistingTodo({
        ...todo,
        createdAt: todo.dateCreated,
        role: inputs.role,
        description: inputs.description,
        alarmTime: null,
        todoId: selectedTodoId,
        username: userName,
      })
        .then(() => {
          setSelectedTodoId("");
        })
        .catch((exception: Error) => {
          setShowError(true);
          log(
            LogLevel.error,
            "TodoEdit",
            `Could not perform api call for updating Todo message with exception: ${exception.message} `,
          );
        });
    }, todo);

  const descriptionCurrentLength = (inputs.description || []).length;
  const disableSubmitButton = descriptionCurrentLength <= 0;
  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();
        if (descriptionCurrentLength > 0) {
          handleSubmit(e);
        }
      }}
    >
      <TodoRowContainer
        style={{
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        {ErrorTextDescriptionElement(
          showError,
          "Kunne ikke lagre endret melding. Vennligst prøv igjen.",
          {
            gridColumn: "span 2",
          },
        )}

        <TextInput
          title="Rediger melding"
          messageText={inputs.description}
          handleInputChange={handleInputChange}
        />
        <Flex role="radiogroup" flexDir="column" gap={1}>
          {ROLE_TEXTS.map((role) => (
            <RadioButton
              key={`${selectedTodoId}-${role.value}`}
              name="role"
              value={role.value}
              checked={inputs.role === role.value}
              onChange={handleInputChange}
            >
              {role.text}
            </RadioButton>
          ))}
        </Flex>

        <ButtonGroup ml="auto" my={1}>
          <Button
            variant="tertiary"
            size="sm"
            onClick={() => {
              setSelectedTodoId("");
            }}
          >
            Avbryt
          </Button>
          <Button
            variant="secondary"
            size="sm"
            type="submit"
            disabled={disableSubmitButton}
          >
            Lagre
          </Button>
        </ButtonGroup>
      </TodoRowContainer>
    </form>
  );
};

export default TodoEdit;
