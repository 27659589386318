import {
  ErrorFill24Icon,
  InformationFill24Icon,
  SuccessFill24Icon,
  WarningFill24Icon,
} from "@vygruppen/spor-icon-react";
import { useTheme } from "styled-components";

export type InfoMessageSeverity = "info" | "success" | "error" | "warning";

type SeverityIconProps = {
  severity: InfoMessageSeverity;
  fill?: string;
};

export const SeverityIcon = ({ severity, fill }: SeverityIconProps) => {
  const theme = useTheme();

  switch (severity) {
    case "info":
      return (
        <InformationFill24Icon
          color={fill ?? theme.colorInfo}
          title="Informasjon"
        />
      );
    case "warning":
      return (
        <WarningFill24Icon color={fill ?? theme.colorWarning} title="Varsel" />
      );
    case "success":
      return (
        <SuccessFill24Icon color={fill ?? theme.colorSuccess} title="Suksess" />
      );
    case "error":
      return (
        <ErrorFill24Icon color={fill ?? theme.colorAlarm} title="Feilmelding" />
      );
    default:
      return null;
  }
};

export const SeverityColor = (severity: InfoMessageSeverity) => {
  const theme = useTheme();

  switch (severity) {
    case "info":
      return theme.colorInfo;
    case "warning":
      return theme.colorWarning;
    case "success":
      return theme.colorSuccess;
    case "error":
      return theme.colorAlarm;
    default:
      return theme.colorTextMain;
  }
};
