import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { axiosClient } from "api/http/config";
import { getBackendUrl } from "api/common";
import {
  VehicleAlertResponse,
  VehicleAlertResponseSchema,
} from "features/CenterContent/shared/TilsynAlerts/schema";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import TilsynAlerts from "features/CenterContent/shared/TilsynAlerts/TilsynAlerts";
import { DetailsParams } from "../TrainDetails";

const VehicleAlerts = () => {
  const { trainNumber, trainDate } = useParams<DetailsParams>();

  const { data, isLoading, isError, isSuccess } = useQuery({
    queryKey: ["getActiveVehicleAlertsForTrain", trainNumber, trainDate],
    refetchInterval: 60000,
    queryFn: ({ signal }) =>
      axiosClient
        .get<VehicleAlertResponse>(
          `${getBackendUrl()}/vehicle-alerts/NO/${trainDate}/${trainNumber}`,
          {
            signal,
          },
        )
        .then((res) => VehicleAlertResponseSchema.parse(res.data)),
  });

  if (isError) {
    return (
      <FailureMessage customMessage="Kunne ikke hente alarmer i Tilsyn for dette toget." />
    );
  }

  if (isLoading || !data || data.alerts.length <= 0) {
    return null;
  }

  return (
    <TilsynAlerts
      alerts={data?.alerts}
      isLoading={false}
      isSuccess={isSuccess}
      style={{ maxWidth: "800px" }}
    />
  );
};

export default VehicleAlerts;
