import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { dropsRole } from "stores/dropsRole";
import { getDashboardStartPath } from "shared/utils/routes";
import { useEventMode } from "stores/useStretchBuilder";
import { EventInteractionBar } from "features/CenterContent/RoleContent/EventInteraction/EventInteractionBar";
import { getTabsForRole } from "features/CenterContent/RoleContent/getTabsForRole";
import Tabs from "features/CenterContent/shared/Tabs/Tabs";

export const RoleContent = () => {
  const { tabIdParam, roleIdParam } = useParams();
  const { role, isVaktleder } = dropsRole();
  const navigate = useNavigate();
  const eventMode = useEventMode((state) => state.eventMode);

  useEffect(() => {
    if (role !== roleIdParam) {
      navigate(getDashboardStartPath(role));
    }
  }, [role]);

  const TabsForRole = getTabsForRole(role);

  const TabPage = TabsForRole?.find((it) => it.id === tabIdParam);
  return (
    <>
      {isVaktleder() && eventMode ? <EventInteractionBar /> : <Tabs />}
      {TabPage?.content}
    </>
  );
};
