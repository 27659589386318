import { Tooltip } from "@chakra-ui/react";
import { Badge, Flex, VStack } from "@vygruppen/spor-react";
import { CallButton } from "shared/components/buttons/CallButton";
import { useTheme } from "styled-components";
import { TrainData } from "./types";
import { ButtonGroup, ButtonStyled } from "./styles";

const getColorSchemeForEvent = (priority: number) => {
  if (priority <= 3) {
    return "red";
  }
  if (priority <= 5) {
    return "light-yellow";
  }

  return "light-blue";
};

type trainEventTitlesSchema = {
  type: string;
  priority: number;
};

const trimType = (type: string) => {
  const trimmed = type.split(" (")[0];
  return trimmed.length > 15 ? `${trimmed.substring(0, 12)}...` : trimmed;
};
export const RightContent = ({
  trainStaffOnDuty,
  eventNames: [firstEvent, ...otherEvents],
}: {
  trainStaffOnDuty: TrainData["trainStaffOnDuty"];
  eventNames: trainEventTitlesSchema[];
}) => {
  const theme = useTheme();

  return (
    <ButtonGroup role="group">
      <VStack spacing={2} alignItems="flex-end">
        <Flex wrap="wrap" justifyContent="flex-end" gap="3px">
          {firstEvent && (
            <Badge
              colorScheme={getColorSchemeForEvent(firstEvent.priority)}
              size="xs"
            >
              {trimType(firstEvent.type)}
            </Badge>
          )}

          {otherEvents.length > 0 && (
            <Tooltip
              hasArrow
              placement="right"
              label={
                <VStack>
                  {otherEvents.map((event) => (
                    <Badge
                      size="xs"
                      colorScheme={getColorSchemeForEvent(event.priority)}
                      key={event.type}
                    >
                      {trimType(event.type)}
                    </Badge>
                  ))}
                </VStack>
              }
              padding="8px"
              bg={theme.colorBackgroundMain}
              border={`1px solid ${theme.borderColor}`}
            >
              <ButtonStyled>
                <Badge
                  colorScheme={getColorSchemeForEvent(
                    Math.min(...otherEvents.map((event) => event.priority)),
                  )}
                  size="xs"
                >
                  +{otherEvents.length}
                </Badge>
              </ButtonStyled>
            </Tooltip>
          )}
        </Flex>
        <Flex wrap="wrap" justifyContent="flex-end" gap="3px">
          {trainStaffOnDuty?.map((staffOnDuty) => (
            <CallButton
              key={staffOnDuty.gsmrNumber}
              label={staffOnDuty.function}
              phoneNumber={staffOnDuty.functionalTelephoneNumber}
              size="xs"
            />
          ))}
        </Flex>
      </VStack>
    </ButtonGroup>
  );
};
