import { Tooltip } from "@chakra-ui/react";
import { useMutation, UseQueryResult } from "@tanstack/react-query";
import { Button, ButtonGroup, Flex, Text } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { mutationFnPOST } from "api/tanStackQuery/helpers";
import { HistoryInformationDetailResponse } from "features/History/HistoryDetail/fetchHistoryDetail";
import { FC, useState } from "react";
import { FailureMessageWithRetryButton } from "shared/components/feedback/FailureMessageWithRetryButton";

type DepublishInfoProps = {
  uuid: string;
  isDisabled: boolean;
  detailsByUuidResult: UseQueryResult<HistoryInformationDetailResponse>;
};

export const DepublishInformation: FC<DepublishInfoProps> = ({
  uuid,
  isDisabled,
  detailsByUuidResult,
}) => {
  const [askConfirmation, setAskConfirmation] = useState<boolean>(false);
  const { mutate: depublish, status: depublishStatus } = useMutation({
    mutationFn: () =>
      mutationFnPOST<string, undefined>(
        `${getBackendUrl()}/operational-information/${uuid}/depublish`,
      ),
    onSuccess: () => {
      setAskConfirmation(false);
      detailsByUuidResult.refetch();
    },
  });

  if (depublishStatus === "error") {
    return (
      <FailureMessageWithRetryButton
        customMessage="Kunne ikke depublisere hendelsen"
        retryFunction={() => depublish()}
      />
    );
  }

  return askConfirmation ? (
    <Flex alignItems="center" gap="8px">
      <Text>Er du sikker?</Text>
      <ButtonGroup>
        <Button
          variant="primary"
          size="xs"
          onClick={() => depublish()}
          isLoading={depublishStatus === "pending"}
        >
          Ja, depubliser
        </Button>
        <Button
          variant="tertiary"
          size="xs"
          onClick={() => setAskConfirmation(false)}
        >
          Avbryt
        </Button>
      </ButtonGroup>
    </Flex>
  ) : (
    <Tooltip
      label="Kan ikke depublisere åpen eller allerede usynlig hendelse"
      hasArrow
      placement="left"
      isDisabled={!isDisabled}
      shouldWrapChildren
    >
      <Button
        variant="tertiary"
        size="xs"
        onClick={() => setAskConfirmation(true)}
        isDisabled={isDisabled}
      >
        Depubliser
      </Button>
    </Tooltip>
  );
};
