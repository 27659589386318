import { UserRole } from "shared/types/roles";

const VY_DOMAIN_NAME = "@vy.no";
const TEST_DOOMAIN_NAME = "@testdomenet.no";

const removeDomainNameFromUsername = (emailAddress: string) =>
  emailAddress.slice(0, emailAddress.indexOf("@"));

export const removeDomainNameIfPresent = (
  username: string | null | undefined,
) => {
  if (!username) return "";
  if (
    !(username.includes(TEST_DOOMAIN_NAME) || username.includes(VY_DOMAIN_NAME))
  )
    return username;
  return removeDomainNameFromUsername(username);
};

export function getDefaultReceivingRoles(role: UserRole): UserRole[] {
  switch (role) {
    case UserRole.lokleder1:
    case UserRole.lokleder2:
      return [
        UserRole.materiellstyrerlokal,
        UserRole.materiellstyrerregion,
        UserRole.lokleder1,
        UserRole.lokleder2,
      ];
    default:
      return [role];
  }
}

export function getAbbreviationForRole(role: UserRole): string {
  switch (role) {
    case UserRole.vaktleder:
      return "VL";
    case UserRole.transportleder:
      return "TL";
    case UserRole.personellstyrerkond:
      return "PSK";
    case UserRole.personellstyrerlok:
      return "PSL";
    case UserRole.personellstyrervest:
      return "PSV";
    case UserRole.materiellstyrerlokal:
      return "MSL";
    case UserRole.materiellstyrerregion:
      return "MSR";
    case UserRole.trafikkplanlegger:
      return "TP";
    case UserRole.lokleder1:
      return "LL1";
    case UserRole.lokleder2:
      return "LL2";
    default:
      return "Unknown Role";
  }
}
