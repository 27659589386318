import { TodoOplogg } from "features/TodoOplogg/TodoOplogg";
import VehicleAffected from "features/VehicleAffected/VehicleAffected";
import { FC } from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";

const CenterContentWrapper = styled.div`
  grid-area: main;
  padding-bottom: 6px;
  box-sizing: border-box;
  min-height: calc(
    100vh - 5rem
  ); // https://css-tricks.com/preventing-a-grid-blowout/
`;

export const DashboardLayout: FC = () => (
  <>
    <VehicleAffected />
    <CenterContentWrapper>
      <Outlet />
    </CenterContentWrapper>
    <TodoOplogg />
  </>
);
