import { Button, HStack, Table, Text, tokens, Tr } from "@vygruppen/spor-react";
import styled from "styled-components";
import { ObtrusiveScrollCss } from "../../../shared/components/transitions/scroll";
import colors from "../../../shared/theme/colors";
import { darkTheme } from "../../../shared/theme/darkTheme";

export const HistoryCustomTableStyle = styled(Table)`
  table {
    border-collapse: separate;
    border-spacing: 0 6px;
  }

  td,
  th {
    border-bottom: none;
    padding-left: 8px;
    padding-right: 6px;
    background-color: ${({ theme }) => theme.colorBackgroundTertiary};
  }

  th {
    background-color: ${({ theme }) => theme.colorBackgroundTertiary};
  }

  tr > td:first-of-type,
  tr > th:first-of-type {
    border-top-left-radius: ${tokens.default.size["border-radius"].xs};
    border-bottom-left-radius: ${tokens.default.size["border-radius"].xs};
  }

  tr > td:last-of-type,
  tr > th:last-of-type {
    border-top-right-radius: ${tokens.default.size["border-radius"].xs};
    border-bottom-right-radius: ${tokens.default.size["border-radius"].xs};
  }
`;

export const VisibilityIndicator = styled.div<{ $isVisible: boolean }>`
  width: 20px;
  height: 20px;
  background-color: ${({ $isVisible }) =>
    $isVisible
      ? tokens.default.color.alias.greenHaze
      : tokens.default.color.alias.brightRed};
  border-radius: 100%;
  border: solid 5px
    ${({ $isVisible }) =>
      $isVisible
        ? tokens.default.color.alias.coralGreen
        : tokens.default.color.alias.lightRed};
`;

export const CustomText = styled(Text)`
  @container (width < 1200px) {
    &&& {
      display: none;
    }
  }
`;

export const HistoryWrapper = styled.div`
  container-type: inline-size;
  grid-area: 2 / 1 / 3 / 4;
  box-sizing: border-box;
  width: 100%;
  background-color: ${({ theme }) => theme.colorBackgroundSecondary};
  ${ObtrusiveScrollCss};
  scrollbar-gutter: stable;
  overflow-y: auto;
  height: 100%; // <1200px
  border-radius: 6px;
  border: 1px solid ${colors.dimGrey};
  padding-left: 30px;
  padding-right: 30px;

  @container (width < 1200px) {
    .DontShowIfSmallWidth {
      display: none;
    }
  }
`;

type Props = {
  $selected?: boolean;
};

export const ButtonFit = styled(Button)<Props>`
  min-width: auto !important;
  font-weight: bold;

  &&& {
    ${({ $selected, theme }) =>
      $selected ? `background-color: ${theme.colorSelected};` : ""}
    ${({ $selected }) =>
      $selected ? `color: ${darkTheme.colorTextMain};` : ""}

        padding-inline: 0;
    padding: 5.5px 12px;
  }

  & p {
    font-weight: bold;
  }
`;

export const ResponsiveHStack = styled(HStack)`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export const CustomResponsiveHStack = styled(HStack)`
    gap: 1.5rem;
    width: 100%;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    @container (width < 1200px) {
     {
    gap: 0.2rem;
    }
}
`;

export const CustomResponsiveTr = styled(Tr)`
  display: grid;
  grid-template-columns: 130px 150px 140fr 100px 190px 300fr;
  @container (width < 1200px) {
    grid-template-columns: 130px 150px 140px 190px 300fr;
  }
  margin-bottom: 5px;
`;
