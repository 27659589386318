import { zodResolver } from "@hookform/resolvers/zod";
import {
  Button,
  Checkbox,
  CheckboxGroup,
  ChoiceChip,
  Divider,
  Expandable,
  FormControl,
  Stack,
  Text,
} from "@vygruppen/spor-react";

import { Grid, HStack } from "@chakra-ui/react";
import { InputTextarea } from "features/TodoOplogg/common/InputTextarea";
import { ShowIfTrue } from "features/TodoOplogg/common/ShowIfTrue";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ROLE_TEXTS, UserRole } from "shared/types/roles";
import styled from "styled-components";
import { dropsRole } from "stores/dropsRole";
import { getDefaultReceivingRoles } from "features/TodoOplogg/common/utils";
import { getAbbreviationForRole } from "../common/utils";
import {
  OploggForm,
  VEHICLE_SET_TYPES,
  commonPadding,
  oploggForm,
} from "../formSchema";
import { usePost, usePostStatus, usePostTodo } from "./useOploggContext";

const FormContainer = styled.div`
  position: relative;
  display: relative;
`;

const LabelContainer = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;
  padding-bottom: 10px;
`;

const ExpandableSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 0 auto;
  padding-bottom: 12px;
`;

interface ExpandableWrapperProps {
  marginLeft?: boolean;
}

const ExpandableWrapper = styled.div<ExpandableWrapperProps>`
  flex: 1;
  margin-left: ${(props) => (props.marginLeft ? "10px" : "0")};
`;

interface OploggSendMessageProps {
  setMessaging: (value: boolean) => void;
}

export const OploggSendMessage = ({ setMessaging }: OploggSendMessageProps) => {
  const post = usePost();
  const postToDo = usePostTodo();
  const { role: currentUserRole } = dropsRole();

  const postStatus = usePostStatus();
  const [sendToTodo, setSendToTodo] = useState(false);
  const [roles, setRoles] = useState<UserRole[]>(
    getDefaultReceivingRoles(currentUserRole),
  );
  const [vehicleSetTypes, setVehicleSetTypes] = useState<string[]>([]);

  const toggleVehicleSetType = (value: any) => {
    setVehicleSetTypes((prevTypes) =>
      prevTypes.includes(value)
        ? prevTypes.filter((type) => type !== value)
        : [...prevTypes, value],
    );
  };

  const formMethods = useForm<OploggForm>({
    resolver: zodResolver(oploggForm),
    defaultValues: {
      message: "",
    },
  });

  const {
    reset,
    handleSubmit,
    formState: { errors },
  } = formMethods;

  const onSubmit = handleSubmit((data) => {
    post(data);

    if (sendToTodo) {
      const todoData = {
        description: data.message,
        roles: roles || [],
        vehicleSetTypes: [vehicleSetTypes.join(",")], // this formatting works for the backend, should it be this way?
      };
      postToDo(todoData);
    }
    reset();
  });

  return (
    <>
      <Grid gridArea="todooplogg" gap={2} padding={commonPadding}>
        <FormProvider {...formMethods}>
          <form onSubmit={onSubmit}>
            <FormControl>
              <Text fontWeight="bold" paddingBottom={1}>
                Ny melding
              </Text>
              <InputTextarea label="Skriv inn tekst her" formField="message" />
            </FormControl>
            <FormContainer>
              <LabelContainer>
                <Checkbox
                  isChecked={sendToTodo}
                  onChange={() => setSendToTodo(!sendToTodo)}
                />
                <Text variant="xs" pl="5px">
                  Send også til Todo
                </Text>
              </LabelContainer>

              <ShowIfTrue show={sendToTodo}>
                <ExpandableSection>
                  <ExpandableWrapper>
                    <Expandable allowToggle title="Send til">
                      <Stack>
                        <CheckboxGroup
                          direction="column"
                          value={roles}
                          onChange={(val: UserRole[]) => {
                            setRoles(val);
                          }}
                        >
                          {ROLE_TEXTS.map((role) => (
                            <Checkbox
                              key={role.value}
                              name="role"
                              value={role.value}
                            >
                              {getAbbreviationForRole(role.value)}
                            </Checkbox>
                          ))}
                        </CheckboxGroup>
                      </Stack>
                    </Expandable>
                  </ExpandableWrapper>

                  <ExpandableWrapper marginLeft>
                    <Expandable allowToggle title="Marker med">
                      <HStack maxWidth="375px" wrap="wrap">
                        {VEHICLE_SET_TYPES.map((vh, i) => (
                          <ChoiceChip
                            key={i}
                            isChecked={vehicleSetTypes.includes(vh.value)}
                            onChange={() => toggleVehicleSetType(vh.value)}
                          >
                            {vh.text}
                          </ChoiceChip>
                        ))}
                      </HStack>
                    </Expandable>
                  </ExpandableWrapper>
                </ExpandableSection>
              </ShowIfTrue>
              <Button
                type="submit"
                size="sm"
                variant="primary"
                isLoading={postStatus === "pending"}
                ml="auto"
                data-testid="submit-button-oplogg"
                onClick={() => {
                  setTimeout(() => {
                    setMessaging(false);
                  }, 500); // Delay of 0.5 sec
                }}
              >
                Send
              </Button>
            </FormContainer>
          </form>
          {errors.message && (
            <Text variant="xs" color="red">
              {errors.message.message}
            </Text>
          )}
        </FormProvider>
      </Grid>
      <Divider />
    </>
  );
};
