import { ChoiceChip } from "@vygruppen/spor-react";
import styled from "styled-components";

export const ChoiceChipForInfoBackground = styled(ChoiceChip)`
  text-wrap: nowrap;

  > div:first-of-type {
    background-color: ${({ isChecked, theme }) =>
      isChecked ? theme.colorInfoFillPulse : theme.colorInfoFill};
    outline-color: ${({ theme }) => theme.colorInfo};
    outline-width: ${({ isChecked }) => (isChecked ? "2px" : "1px")};

    &:hover {
      background-color: ${({ theme }) => theme.colorInfoFill};
      outline-width: 2px;
      outline-color: ${({ theme }) => theme.colorInfo};
    }

    &:active {
      background-color: ${({ theme }) => theme.colorInfoFillPulse};
      outline-width: 2px;
      outline-color: ${({ theme }) => theme.colorInfo};
    }

    > span {
      color: ${({ theme }) => theme.colorInfoTextMain};
    }
  }
`;
