import { CalendarOutline24Icon } from "@vygruppen/spor-icon-react";
import { Button } from "@vygruppen/spor-react";
import { UpcomingEventsModal } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/UpcomingEventsModal";
import { useState } from "react";
import { useTheme } from "styled-components";

export const UpcomingEventsButtonAndModal = () => {
  const theme = useTheme();
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <Button
        variant="tertiary"
        sx={{
          backgroundColor: theme.colorBackgroundSecondary,
          width: "fit-content",
        }}
        leftIcon={<CalendarOutline24Icon />}
        aria-label="Se fremtidige hendelser"
        onClick={() => setShowModal(true)}
      >
        Fremtidige hendelser
      </Button>
      {showModal && <UpcomingEventsModal onClose={() => setShowModal(false)} />}
    </>
  );
};
