import React, { Dispatch, SetStateAction, useState } from "react";

type Callback = () => void;

export interface LooseObject {
  [key: string]: any;
}

export type HandleInputChange = (event: React.ChangeEvent<any>) => void;

type handleSubmit = (event: React.FormEvent<HTMLFormElement>) => void;

export interface UseFormReturnType {
  handleSubmit: handleSubmit;
  handleInputChange: HandleInputChange;
  inputs: LooseObject;
  setInputs: Dispatch<SetStateAction<LooseObject>>;
}

const useForm = (
  callbackOnSubmit: Callback,
  initInputs: LooseObject = {},
): UseFormReturnType => {
  const [inputs, setInputs] = useState(initInputs);

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    if (event) {
      event.preventDefault();
    }
    setInputs(initInputs);
    callbackOnSubmit();
  };
  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>,
  ) => {
    event.persist();
    setInputs((prevInputs) => {
      const targetValue =
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value;
      return { ...prevInputs, [event.target.name]: targetValue };
    });
  };
  return {
    handleSubmit,
    handleInputChange,
    inputs,
    setInputs,
  };
};

export default useForm;
