import { parseDate } from "@internationalized/date";
import { useQuery } from "@tanstack/react-query";
import {
  Accordion,
  DatePicker,
  FormControl,
  FormLabel,
  Heading,
  Stack,
} from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { axiosClient } from "api/http/config";
import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { format } from "date-fns";
import ISirkNote from "features/CenterContent/RoleContent/ISirk/ISirkNote";
import { incidentNoteResponseSchema } from "features/CenterContent/RoleContent/ISirk/types";
import { UpcomingEventsButtonAndModal } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/UpcomingEventsButtonAndModal";
import { SharedGridStyle } from "features/CenterContent/shared/MainContentGrid";
import { useState } from "react";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import SkeletonLoader from "shared/components/feedback/SkeletonLoader/SkeletonLoader";
import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import { Text } from "shared/components/typography/TitleStyles";
import styled from "styled-components";
import * as z from "zod";

const ISirkWrapper = styled.div`
  ${SharedGridStyle};
  height: 100%;
  color: ${({ theme }) => theme.colorTextMain};
  ${ObtrusiveScrollCss};
`;

const ContentWrapper = styled(Stack)`
  width: 50%;
  height: 100%;
  min-width: 600px;
  max-width: 1000px;
  overflow-y: auto;
`;

const AccordionGroup = styled(Accordion)`
  ${ObtrusiveScrollCss};
`;

const ISirkView = () => {
  const [selectedDate, setSelectedDate] = useState(
    format(new Date(), "yyyy-MM-dd"),
  );
  const formattedDate = format(new Date(selectedDate), "dd.MM.yyyy");

  const { status, data } = useQuery({
    queryKey: ["iSirk", selectedDate],
    queryFn: async ({ signal }) =>
      axiosClient
        .get<z.infer<typeof incidentNoteResponseSchema>>(
          `${getBackendUrl()}/alternative-transport/incident-notes?from=${selectedDate}`,
          {
            signal,
          },
        )
        .then((res) => incidentNoteResponseSchema.parse(res.data)),
  });

  const getContent = () => {
    switch (status) {
      case "pending":
        return <SkeletonLoader skeletonType="list" />;
      case "success": {
        const { incidentNotes } = data;
        return incidentNotes.length ? (
          <AccordionGroup allowToggle overflowY="scroll">
            {incidentNotes.map((note) => (
              <ISirkNote key={note.identifier} {...note} />
            ))}
          </AccordionGroup>
        ) : (
          <Text>Fant ingen gjeldende I-Sirk for {formattedDate}.</Text>
        );
      }
      case "error":
      default:
        return <FailureMessage />;
    }
  };

  return (
    <ISirkWrapper>
      <ContentWrapper mt={2}>
        <UpcomingEventsButtonAndModal />
        <Heading as="h2" variant="sm" style={{ fontWeight: "bold" }}>
          I-Sirk
        </Heading>
        <FormControl label="Viser I-Sirk som er aktive" mb={3}>
          <FormLabel margin={0}>Viser I-Sirk som er aktive:</FormLabel>
          <DatePicker
            label="Dato"
            variant="base"
            minHeight="54px"
            value={parseDate(selectedDate.toString())}
            onChange={(date) => {
              if (date !== null) setSelectedDate(date.toString());
            }}
          />
        </FormControl>
        {getContent()}
      </ContentWrapper>
    </ISirkWrapper>
  );
};

export default withErrorBoundary(ISirkView);
