import React, { useState } from "react";
import { FCWC } from "shared/utils/FCWC";
import styled from "styled-components";
import { ActionButton, ActionButtons } from "../ActionButtons";
import { InfoMessageSeverity } from "../severity";
import { InfoMessageBaseStyling } from "../style";
import { ExpandableButton } from "./ExpandableButton";

const ExpandableMessage = styled.div<{
  severity: InfoMessageSeverity;
  borderColorOverride?: string;
}>`
  ${({ severity, borderColorOverride }) =>
    InfoMessageBaseStyling(severity, borderColorOverride)};
`;

const ExpandablePanel = styled.div`
  display: grid;
  transition: grid-template-rows 500ms;
  margin-left: 30px;
  grid-template-rows: 0fr;

  &[aria-hidden="false"] {
    grid-template-rows: 1fr;
  }

  div {
    overflow: hidden;
    grid-row: 1 / span 2;
  }
`;

interface InfoMessageProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  severity: InfoMessageSeverity;
  title: string;
  subTitle?: string;
  actionButtons?: ActionButton[];
  initialOpenState?: boolean;
  colorOverride?: string;
  hideSubtitleWhileOpen?: boolean;
}

export const ExpandableInfoMessage: FCWC<InfoMessageProps> = ({
  children,
  severity,
  colorOverride,
  title,
  subTitle,
  actionButtons = [],
  initialOpenState = false,
  hideSubtitleWhileOpen = true,
  ...rest
}) => {
  const [isOpen, setIsOpen] = useState(initialOpenState);
  return (
    <ExpandableMessage
      severity={severity}
      borderColorOverride={colorOverride}
      {...rest}
    >
      <ExpandableButton
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        severity={severity}
        title={title}
        subTitle={hideSubtitleWhileOpen && isOpen ? undefined : subTitle}
        iconColorOverride={colorOverride}
      />
      <ExpandablePanel
        id="collapsible_info_message_content"
        aria-hidden={!isOpen}
      >
        <div>
          {children}
          {actionButtons?.length > 0 && (
            <ActionButtons
              actionButtons={actionButtons}
              rightAlign
              style={{
                marginTop: "12px",
              }}
            />
          )}
        </div>
      </ExpandablePanel>
    </ExpandableMessage>
  );
};
