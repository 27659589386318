import { AddOutline24Icon } from "@vygruppen/spor-icon-react";
import { Button, ButtonGroup } from "@vygruppen/spor-react";
import { UpcomingEventsButtonAndModal } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/UpcomingEventsButtonAndModal";
import { FC } from "react";
import { useEventMode, useStretchBuilder } from "stores/useStretchBuilder";
import { useTheme } from "styled-components";

export const CreateEvent: FC = () => {
  const [eventMode, setEventMode] = useEventMode((state) => [
    state.eventMode,
    state.setEventMode,
  ]);
  const setStretchBuilderModalState = useStretchBuilder(
    (state) => state.setStretchBuilderModalState,
  );
  const theme = useTheme();

  return !eventMode ? (
    <ButtonGroup position="absolute" top="12px" left="12px">
      <Button
        _active={{ backgroundColor: theme.colorBackgroundTertiary }}
        leftIcon={<AddOutline24Icon />}
        onClick={() => {
          setEventMode(true);
          setStretchBuilderModalState({
            type: "infrastructure",
            stretchBuilderMode: true,
          });
        }}
      >
        Ny hendelse
      </Button>
      <UpcomingEventsButtonAndModal />
    </ButtonGroup>
  ) : null;
};
