import { ZonedDateTime } from "@internationalized/date";
import { format } from "date-fns";
import {
  Duration,
  InfraStructureInformation,
} from "shared/types/infrastructureResponse";

type OverallDuration = {
  fromTime: ZonedDateTime;
  toTime: ZonedDateTime | null;
};

export const getDuration = (duration: Duration): OverallDuration | null => {
  switch (duration.type) {
    case "TIMED":
      return {
        fromTime: duration.from_time,
        toTime: duration.to_time,
      };
    case "OPEN_ENDED":
      return {
        fromTime: duration.from_time,
        toTime: null,
      };
    case "PERIODIC": {
      if (duration.periods.length === 0) return null;

      const from = duration.periods.sort(
        (a, b) => a.fromTime.toDate().valueOf() - b.fromTime.toDate().valueOf(),
      )[0];
      const to = duration.periods.sort(
        (a, b) => b.toTime.toDate().valueOf() - a.toTime.toDate().valueOf(),
      )[0];

      return {
        fromTime: from.fromTime,
        toTime: to.toTime,
      };
    }
    default:
      return null;
  }
};

export const sortByDuration = (
  a: InfraStructureInformation,
  b: InfraStructureInformation,
) => {
  const durationA = getDuration(a.durations);
  const durationB = getDuration(b.durations);

  const fromTimeA = durationA?.fromTime.toDate().valueOf();
  const fromTimeB = durationB?.fromTime.toDate().valueOf();

  if (fromTimeA === undefined || fromTimeB === undefined) return 0;

  return fromTimeA - fromTimeB;
};

export const getDurationString = (duration: Duration) => {
  const overallDuration = getDuration(duration);
  if (overallDuration === null) return "Ukjent tidsrom.";

  if (!overallDuration.toTime)
    return `Fra kl. ${format(overallDuration.fromTime.toDate(), "HH:mm, dd.MM.yyyy")}`;

  return `Fra ${format(overallDuration.fromTime.toDate(), "dd.MM.yyyy")} - ${format(
    overallDuration.toTime.toDate(),
    "dd.MM.yyyy",
  )}`;
};
