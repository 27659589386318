import React from "react";
import styled from "styled-components";
import { CloseButton } from "@chakra-ui/react";
import { InfoMessageSeverity, SeverityIcon } from "./severity";
import { COLUMN_GAP, InfoMessageBaseStyling } from "./style";
import { Text } from "../../typography/TitleStyles";

const DismissibleMessage = styled.div<{
  severity: InfoMessageSeverity;
}>`
  ${({ severity }) => InfoMessageBaseStyling(severity)};
  display: grid;
  grid-template-columns: auto 1fr auto;
  align-items: center;
  column-gap: ${COLUMN_GAP}px;
`;

interface InfoMessageProps extends React.HtmlHTMLAttributes<HTMLDivElement> {
  severity: InfoMessageSeverity;
  summary: string;
  isDismissed: boolean;
  onDismiss: () => void;
}

export const DismissibleInfoMessage = ({
  severity,
  summary,
  isDismissed,
  onDismiss,
  ...rest
}: InfoMessageProps) =>
  isDismissed ? null : (
    <DismissibleMessage severity={severity} {...rest}>
      <SeverityIcon severity={severity} />
      <Text>{summary}</Text>
      <CloseButton onClick={onDismiss} />
    </DismissibleMessage>
  );
