import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { AffectedStop, Distribution } from "shared/types/historyDetail";

export type Group =
  | "TRAIN"
  | "INFRASTRUCTURE"
  | "STATION"
  | "ALTERNATIVE_TRANSPORT";

type EventType = {
  type: string;
  group: Group;
  label: string | null;
};

export type OriginSystem = {
  reference: string;
  name: string;
  user: string | null;
};

type Reason = {
  type: string;
  description: {
    eng: string | null;
    nob: string | null;
  };
};

type ActionCard = {
  type: string;
};

export type Visibility = {
  type: "TIMED" | "OPEN_ENDED" | "CONSTANT";
  fromTime: Date | null;
  toTime: Date | null;
};

export type State =
  | "OPEN"
  | "CLOSED"
  | "REPLACED"
  | "UNHANDLED"
  | "DEPUBLISHED";

export type Information = {
  // action?
  actionCard: ActionCard | null;
  createdAt: Date;
  distributions: Distribution[];
  isVisible: boolean;
  visibility: Visibility;
  originSystem: OriginSystem;
  reason: Reason;
  state: State;
  stretchName: string;
  affectedStops: AffectedStop[];
  stops: AffectedStop[];
  type: EventType;
  updatedAt: Date;
  uuid: string;
  version: number;
};

export type HistoryInformationDetailResponse = {
  uuid: string;
  versions: Information[];
  replaced: string | null;
};

export const useHistoryDetail = (uuid: string) =>
  useQuery({
    queryKey: ["historyDetails", uuid],
    refetchInterval: 60000,
    queryFn: ({ signal }: { signal: AbortSignal }) =>
      queryFnGET<HistoryInformationDetailResponse>({
        signal,
        url: `${getBackendUrl()}/operational-information/${uuid}`,
      }),
    gcTime: 0,
  });
