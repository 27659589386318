import { CalendarDateTime, toCalendarDateTime } from "@internationalized/date";
import { createRulesFromPeriodicDuration } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/periodicDurationUtils";
import {
  FormSchemaDuration,
  OpenEndedFormDuration,
  PeriodicFormDuration,
  TimedFormDuration,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/formSchema";
import { Duration } from "shared/types/infrastructureResponse";

const DEFAULT_TIME_HORIZON_IN_MINUTES = "150";

export const getCurrentCalendarDateTime = () => {
  const now = new Date();
  return new CalendarDateTime(
    now.getFullYear(),
    now.getMonth() + 1,
    now.getDate(),
    now.getHours(),
    now.getMinutes(),
  );
};

export const getInitialOpenEndedDuration = (): OpenEndedFormDuration => ({
  type: "OPEN_ENDED",
  from_time: getCurrentCalendarDateTime(),
  extension_time_in_minutes: DEFAULT_TIME_HORIZON_IN_MINUTES,
});

export const getInitialTimedDuration = (): TimedFormDuration => ({
  type: "TIMED",
  from_time: getCurrentCalendarDateTime().set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  }),
  to_time: getCurrentCalendarDateTime().set({
    hour: 23,
    minute: 59,
    second: 0,
    millisecond: 0,
  }),
});

export const initialPeriodicDuration: PeriodicFormDuration = {
  type: "PERIODIC",
  rules: [],
};

export const getInitialDuration = (duration: Duration): FormSchemaDuration => {
  switch (duration.type) {
    case "PERIODIC": {
      const rules = createRulesFromPeriodicDuration(duration);
      return { type: "PERIODIC", rules };
    }
    case "TIMED": {
      return {
        type: "TIMED",
        from_time: toCalendarDateTime(duration.from_time),
        to_time: toCalendarDateTime(duration.to_time),
      };
    }

    case "OPEN_ENDED":
    default: {
      return { ...duration, from_time: toCalendarDateTime(duration.from_time) };
    }
  }
};
