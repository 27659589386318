import { useEffect, useState } from "react";
import {
  Badge,
  ChoiceChip,
  Flex,
  Stack,
  StaticAlert,
  Text,
} from "@vygruppen/spor-react";
import {
  TourneyOutline24Icon,
  TourneyFill24Icon,
} from "@vygruppen/spor-icon-react";
import {
  ALTERNATIVE_STRETCHES,
  flipLegDirections,
  formatStretchName,
  orderLegs,
  shouldFlipStretchDirection,
  Stretch as AlternativeStretch,
} from "features/CenterContent/shared/operationalInformation/alternativeStretches";
import { useFormContext } from "react-hook-form";
import { FormSchema } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/formSchema";
import {
  AffectedLeg,
  RedirectStretch,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import { unique } from "shared/utils/arrayUtils";

/**
 * FIXME:
 * Alternative stretches for Lysaker-Asker (id: 8 and 9) that are needed for TrainChangedRoute,
 * but is irrelevant in this component.
 * TrainChangedRoute should be rewritten to use the same logic for combining redirect stretches as
 * we do here for infrastructure events.
 */
const alwaysIgnoredAlternativeStretchIds = [8, 9];

const isEqualLegs = (legA: AffectedLeg, legB: AffectedLeg) =>
  (legA.fromStop === legB.fromStop && legA.toStop === legB.toStop) ||
  (legA.fromStop === legB.toStop && legA.toStop === legB.fromStop);

const findAlternativeStretchesMatchingLegs = (legs: AffectedLeg[]) =>
  ALTERNATIVE_STRETCHES.filter(
    (s) =>
      s.legs.every((leg) => legs.some((l) => isEqualLegs(l, leg))) &&
      !alwaysIgnoredAlternativeStretchIds.includes(s.id),
  );

const isRelevantAlternativeStretch = (
  alternativeStretch: AlternativeStretch,
  selectedStops: string[],
  affectedAlternativeStretches: AlternativeStretch[],
) => {
  const ignoredAlternativeStretchIds = affectedAlternativeStretches.map(
    (s) => s.id,
  );

  const shouldIgnoreStretch =
    ignoredAlternativeStretchIds.includes(alternativeStretch.id) ||
    alwaysIgnoredAlternativeStretchIds.includes(alternativeStretch.id);

  return (
    !shouldIgnoreStretch &&
    selectedStops.includes(alternativeStretch.stops[0]) &&
    selectedStops.includes(
      alternativeStretch.stops[alternativeStretch.stops.length - 1],
    )
  );
};

const toRedirectStretch = (stretch: AlternativeStretch): RedirectStretch => ({
  name: stretch.name,
  legs: stretch.legs,
});

const combineAndMapToRedirectStretches = (
  redirectStretches: RedirectStretch[],
  currentStretchId: number,
): RedirectStretch[] => {
  const currentStretch = ALTERNATIVE_STRETCHES.find(
    (s) => s.id === currentStretchId,
  );
  if (!currentStretch) return redirectStretches;
  const connectedStretch = redirectStretches.find(
    (s) => s.name === currentStretch.name,
  );

  // Filter out related sub stretches and combine them into one stretch of ordered legs.
  if (connectedStretch) {
    return [
      ...redirectStretches.filter((s) => s.name !== currentStretch.name),
      {
        name: connectedStretch.name,
        legs: orderLegs([...connectedStretch.legs, ...currentStretch.legs]),
      },
    ];
  }

  return [...redirectStretches, toRedirectStretch(currentStretch)];
};

export const SelectRedirectStretches = () => {
  const { setValue, getValues } = useFormContext<FormSchema>();
  const prevRedirectStretches = getValues(
    "infrastructureForm.redirectStretches",
  );
  const prevStretchName = getValues("infrastructureForm.stretchName");
  const affectedLegs = getValues("infrastructureForm.affectedLegs");

  const prevAlternativeStretchIds = prevRedirectStretches
    .flatMap((prev) => findAlternativeStretchesMatchingLegs(prev.legs))
    .map((s) => s.id);

  const [selectedAlternativeStretches, setSelectedAlternativeStretches] =
    useState<number[]>(prevAlternativeStretchIds);

  const affectedAlternativeStretches =
    findAlternativeStretchesMatchingLegs(affectedLegs);

  const selectedStops = unique(
    affectedLegs.flatMap((leg) => [leg.fromStop, leg.toStop]),
  );

  const relevantAlternativeStretches = ALTERNATIVE_STRETCHES.filter((stretch) =>
    isRelevantAlternativeStretch(
      stretch,
      selectedStops,
      affectedAlternativeStretches,
    ),
  );

  useEffect(() => {
    const selectedRedirectStretches = selectedAlternativeStretches
      .reduce(combineAndMapToRedirectStretches, [])
      .map((s) => {
        const shouldFlip = shouldFlipStretchDirection(s.legs, selectedStops);
        return {
          ...s,
          legs: shouldFlip ? flipLegDirections(s.legs) : s.legs,
        };
      });

    setValue(
      "infrastructureForm.redirectStretches",
      selectedRedirectStretches,
      {
        shouldValidate: true,
      },
    );
  }, [selectedAlternativeStretches]);

  return (
    <Stack mb={2}>
      <Text variant="xs">Velg omkjøringer:</Text>
      <Flex paddingX="20" alignItems="start" flexWrap="wrap" gap={1}>
        {relevantAlternativeStretches.length === 0 ? (
          <Badge colorScheme="grey" fontWeight="700">
            Ingen omkjøringer finnes
          </Badge>
        ) : (
          relevantAlternativeStretches.map((stretch) => (
            <ChoiceChip
              key={stretch.id}
              icon={{
                default: <TourneyOutline24Icon />,
                checked: <TourneyFill24Icon />,
              }}
              variant="base"
              fontWeight="700"
              size="sm"
              isChecked={selectedAlternativeStretches.includes(stretch.id)}
              onChange={() => {
                if (!selectedAlternativeStretches.includes(stretch.id)) {
                  setSelectedAlternativeStretches((prev) =>
                    prev ? [...prev, stretch.id ?? []] : [stretch.id],
                  );
                } else {
                  setSelectedAlternativeStretches((prev) =>
                    prev ? prev.filter((id) => id !== stretch.id) : [],
                  );
                }
              }}
            >
              {formatStretchName(
                stretch,
                shouldFlipStretchDirection(stretch.legs, selectedStops),
              )}
            </ChoiceChip>
          ))
        )}
      </Flex>
    </Stack>
  );
};
