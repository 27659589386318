import { CloseFill24Icon } from "@vygruppen/spor-icon-react";
import { Button } from "@vygruppen/spor-react";
import { FC } from "react";
import { DefaultTheme, useTheme } from "styled-components";

export type EventTypeButton = {
  title: string;
  isDisabled: boolean;
  onClick: () => void;
};

type EventButtonProps = {
  button: EventTypeButton;
  cancelIcon?: boolean;
};

const getButtonStyleForInfoBackground = (theme: DefaultTheme) => ({
  color: theme.colorInfoTextMain,
  outlineColor: theme.colorInfo,
  "&:hover": {
    color: theme.colorInfoTextMain,
    outlineColor: theme.colorInfo,
  },
  "&:disabled": {
    color: theme.colorInfoTextMain,
    backgroundColor: theme.colorInfoFillPulse,
    outline: "none",
    opacity: 0.5,
  },
});

export const EventButton: FC<EventButtonProps> = ({
  button,
  cancelIcon = false,
}) => {
  const theme = useTheme();
  return (
    <Button
      key={button.title}
      size="xs"
      variant="tertiary"
      onClick={button.onClick}
      isDisabled={button.isDisabled}
      leftIcon={cancelIcon ? <CloseFill24Icon /> : undefined}
      sx={getButtonStyleForInfoBackground(theme)}
    >
      {button.title}
    </Button>
  );
};
