import {
  TimeOutline18Icon,
  WarningOutline18Icon,
} from "@vygruppen/spor-icon-react";
import { getDurationString } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/utils";
import { FC } from "react";
import styled from "styled-components";
import { Text } from "shared/components/typography/TitleStyles";
import { HStack } from "@vygruppen/spor-react";
import { Duration } from "shared/types/infrastructureResponse";

const CustomOutlineBadge = styled.div`
  border-radius: 30px;
  border: 1px solid ${({ theme }) => theme.colorOutline};
  padding: 0 6px 0 6px;
`;

type DurationProps = {
  isPlanned: boolean;
  duration: Duration;
};

export const DurationBadges: FC<DurationProps> = ({ isPlanned, duration }) => (
  <HStack>
    <CustomOutlineBadge>
      <HStack gap={1}>
        {isPlanned ? <TimeOutline18Icon /> : <WarningOutline18Icon />}
        <Text>{isPlanned ? "Planlagt" : "Oppstått"}</Text>
      </HStack>
    </CustomOutlineBadge>
    <CustomOutlineBadge>
      <Text>{getDurationString(duration)}</Text>
    </CustomOutlineBadge>
  </HStack>
);
