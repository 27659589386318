import { useEffect, useState } from "react";
import { format } from "date-fns";
import { ClockWrapper, Heartbeat } from "features/Header/Clock/ClockStyle";

const Clock = () => {
  const [updateClock, setUpdateClock] = useState(false);

  useEffect(() => {
    const timerID = setInterval(() => setUpdateClock(!updateClock), 1000);

    return function cleanup() {
      clearInterval(timerID);
    };
  });

  return (
    <ClockWrapper>
      {format(new Date(), "HH")}
      <Heartbeat>:</Heartbeat>
      {format(new Date(), "mm")}
    </ClockWrapper>
  );
};

export default Clock;
