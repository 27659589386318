import {
  TrainInfoMessage,
  TrainInfoState,
  TrainInformationAggregated,
} from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationAggregated";
import {
  EventType,
  GenericType,
} from "features/CenterContent/shared/operationalInformation/hooks/useOperationalInformationTypes";
import { compareDateStringAsc } from "shared/utils/datetime";
import { TrainEventTypeEnum } from "./types/trainEventTypeEnum";

export const getUrgencySeverityMapping = (trainInfo: TrainInfoMessage) => {
  if (trainInfo.state === "CLOSED" || trainInfo.state === "DEPUBLISHED") {
    return "success";
  }

  if (trainInfo.priority <= 3) {
    return "error";
  }
  if (trainInfo.priority <= 5) {
    return "warning";
  }

  return "info";
};

/**
 * A comparison function for sorting a list of train information by urgency, state and updatedAt.
 *
 * Closed information will be lower than Open information.
 * Highest priority on top  - closed information at bottom. Information with the same priority will
 * be sorted by updatedAt, with the newest information on top.
 *
 * */
export const sortTrainInfoByUrgencyStateCompareFn = (
  a: TrainInformationAggregated,
  b: TrainInformationAggregated,
): number => {
  if (a.versions.length <= 0 || b.versions.length <= 0) {
    return -1;
  }

  const newestVersionA = a.versions[0];
  const newestVersionB = b.versions[0];

  // there will be no REPLACED states, as they are filtered out before sorting
  const stateEnum = Object.values(TrainInfoState);

  return (
    // sort by state: OPEN before CLOSED, then DEPUBLISHED
    stateEnum.indexOf(newestVersionA.state) -
      stateEnum.indexOf(newestVersionB.state) ||
    // sort by priority (highest priority is 1, lowest is 10)
    newestVersionA.priority - newestVersionB.priority ||
    // sort by updatedAt, newest first
    compareDateStringAsc(newestVersionA.updatedAt, newestVersionB.updatedAt)
  );
};

/**
 * Returns true for train events that support multiple events of the same type per train. Example: there can be
 * multiple train cancellations for the same train, for different parts of the route
 */
export const supportsMultipleEvents = (
  event: EventType | GenericType,
): boolean => {
  const supportedEvents: Array<EventType["type"]> = [
    TrainEventTypeEnum.TRAIN_CANCELLED,
    TrainEventTypeEnum.TRAIN_CUSTOM,
    TrainEventTypeEnum.TRAIN_CHANGED_ROUTE,
  ];

  return supportedEvents.includes(event.type);
};
