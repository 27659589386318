import { VehicleType } from "shared/types/alternativeTransport";
import {
  BusFill30Icon,
  MaxitaxiFill30Icon,
  MinibussFill30Icon,
  TaxiFill30Icon,
} from "@vygruppen/spor-icon-react";
import { ForwardRefExoticComponent } from "react";
import { BoxProps } from "@chakra-ui/react";

type Icon = ForwardRefExoticComponent<BoxProps>;
export const vehicleTypeToIcon = new Map<VehicleType, [Icon, string]>([
  ["BUS", [BusFill30Icon, "Buss"]],
  ["MINIBUS", [MinibussFill30Icon, "Minibuss"]],
  ["TAXI", [TaxiFill30Icon, "Taxi"]],
  ["MAXITAXI", [MaxitaxiFill30Icon, "Maxitaxi"]],
  ["UNKNOWN", [BusFill30Icon, "Ukjent kjøretøy"]],
]);

type Truthy<T> = T extends false | "" | 0 | null | undefined ? never : T; // from lodash

export function truthy<T>(value: T): value is Truthy<T> {
  return !!value;
}
