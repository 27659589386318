import {
  ArrowDownFill24Icon,
  ArrowUpFill24Icon,
  ArrowUpOutline24Icon,
} from "@vygruppen/spor-icon-react";
import {
  Button,
  Divider,
  IconButton,
  Stack,
  tokens,
} from "@vygruppen/spor-react";
import { getLocalStorageUserEmail } from "api/dropsLocalStorage";
import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import Clock from "features/Header/Clock/Clock";
import { OploggElement } from "features/TodoOplogg/Oplogg/OploggElement/OploggElement";
import {
  useData,
  useFetchNextPage,
  useHasNextPage,
  useIsFetchingNextPage,
  useStatus,
} from "features/TodoOplogg/Oplogg/useOploggContext";
import { commonPadding } from "features/TodoOplogg/formSchema";
import {
  ReactElement,
  ReactNode,
  UIEventHandler,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { NoDataMessage } from "shared/components/feedback/NoDataMessage/NoDataMessage";
import SkeletonLoader from "shared/components/feedback/SkeletonLoader/SkeletonLoader";
import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import styled, { DefaultTheme, keyframes } from "styled-components";
import { getId } from "./OploggElement/utils";

export const OploggMessageWrapper = styled.div`
  grid-area: messages;
  flex-direction: column;
  height: 100%;
  overflow-y: hidden;
`;

export const OploggList = styled.ul<{ $readonly: boolean }>`
  ${ObtrusiveScrollCss};
  display: flex;
  flex-direction: column;
  overflow-y: ${({ $readonly }) => ($readonly ? "hidden" : "scroll")};
  height: 100%;
  width: 100%;
  margin: 0;
  list-style: none;
  padding: 6px;
  gap: 3px;
  scroll-behavior: smooth;
  position: relative;

  &:has(li[data-show-new-indicator="true"]) .indicators .moremsgs {
    display: flex !important;
  }

  &:has(li[data-show-bookmarked-indicator="true"]) .indicators .bookmarked {
    display: flex !important;
  }
`;

const PulseButtonAnimation = (theme: DefaultTheme) => keyframes`
  0% {
    background-color: ${theme.oploggMoreMsgsNormal};
  }
  100% {
    background-color: ${theme.oploggMoreMsgsDarker};
  }
`;

interface InidicatorProps {
  placement: "top" | "bottom";
}

const Indicators = styled.div<InidicatorProps>`
  display: flex;
  gap: 6px;
  justify-content: center;
  position: sticky;
  ${({ placement }) =>
    placement === "top" ? "top: 6px; order: -1;" : "bottom: 6px;"}
`;

const NewIndicatorButton = styled(Button)`
  display: none !important;
  animation: ${({ theme }) => PulseButtonAnimation(theme)} 1s ease-in-out
    alternate;
  animation-iteration-count: infinite;
  background-color: ${({ theme }) => theme.oploggMoreMsgsNormal} !important;
  align-self: flex-start;

  &:hover {
    background-color: ${({ theme }) => theme.oploggMoreMsgsDarker} !important;
  }
`;

const NewIndicator = ({
  children,
  onClick,
}: {
  children: ReactNode[];
  onClick: () => void;
}) => (
  <NewIndicatorButton
    className="moremsgs"
    size="md"
    leftIcon={<ArrowUpOutline24Icon />}
    aria-label="Gå til nye uleste meldinger"
    onClick={onClick}
  >
    {...children}
  </NewIndicatorButton>
);

const BookmarkIndicatorButton = styled(IconButton)`
  display: none !important;
  align-self: flex-end;
`;

const BookmarkIndicator = ({
  icon,
  onClick,
}: {
  icon: ReactElement;
  onClick: () => void;
}) => (
  <BookmarkIndicatorButton
    className="bookmarked"
    aria-label="Gå til bokmerket melding"
    variant="floating"
    icon={icon}
    onClick={onClick}
  />
);

export const BorderHr = styled.hr`
  color: ${({ theme }) => theme.colorSeparationLine};
  margin-left: -${tokens.default.size.spacing[commonPadding]};
  margin-right: -${tokens.default.size.spacing[commonPadding]};
`;

const debounce = (
  callback: (...args: unknown[]) => void,
  timeoutMs: number,
) => {
  let timeoutId: number | undefined;

  return (...args: unknown[]) => {
    window.clearTimeout(timeoutId);
    timeoutId = window.setTimeout(() => {
      callback(...args);
    }, timeoutMs);
  };
};

type OploggMessagesProps = {
  showHeader?: boolean;
  readonly?: boolean;
};

const OploggMessages = ({
  showHeader = false,
  readonly = false,
}: OploggMessagesProps) => {
  const data = useData();

  const status = useStatus();

  const hasNextPage = useHasNextPage();
  const isFetchingNextPage = useIsFetchingNextPage();
  const fetchNextPage = useFetchNextPage();
  const userEmail = getLocalStorageUserEmail();

  const containerRef = useRef<HTMLUListElement | null>(null);
  const [indicatorsState, setIndicatorsState] = useState<{
    moreMsgsCounter: number;
    highlightedEl: Element | null;
  }>({
    moreMsgsCounter: 0,
    highlightedEl: null,
  });

  const [highlightedLogId, setHighlightedLogId] = useState<string>("");
  const onHighlightItem = (id: string) => {
    setHighlightedLogId(id === highlightedLogId ? "" : id);
  };

  const [bookmarkIsAbove, setBookmarkIsAbove] = useState(false);

  const checkBookmarkIsAbove: UIEventHandler<HTMLUListElement> = () => {
    const indicatorElement = document.querySelector("[data-bookmarked]");
    const refElement = containerRef.current;

    if (indicatorElement && refElement) {
      const indicatorRect = indicatorElement.getBoundingClientRect();
      const refRect = refElement.getBoundingClientRect();

      const indicatorMiddle = (indicatorRect.top + indicatorRect.bottom) / 2;
      const refMiddle = (refRect.top + refRect.bottom) / 2;

      setBookmarkIsAbove(indicatorMiddle < refMiddle);
    }
  };

  const getHighlightedEl: UIEventHandler<HTMLUListElement> = debounce(() => {
    const elements = Array.from(containerRef.current?.children ?? []);

    const highlightedEl = elements.find((el) =>
      el.getAttribute("data-show-bookmarked-indicator"),
    );

    setIndicatorsState((prevState) => ({
      ...prevState,
      highlightedEl: highlightedEl ?? null,
    }));
  }, 500);

  const onScroll: UIEventHandler<HTMLUListElement> = (event) => {
    checkBookmarkIsAbove(event);
    getHighlightedEl(event);
  };

  const goToTop = () => containerRef.current?.scrollTo(0, 0);

  const goToBookmark = () => {
    indicatorsState.highlightedEl?.scrollIntoView({
      behavior: "smooth",
      inline: "center",
      block: "center",
    });
  };

  const allEntries = useMemo(
    () =>
      data?.pages
        .flatMap((group) => group.entries)
        .sort(
          (a, b) =>
            new Date(b.reportedTime ?? Date.now()).valueOf() -
            new Date(a.reportedTime ?? Date.now()).valueOf(),
        ),
    [data],
  );

  useEffect(() => {
    const elements = Array.from(containerRef.current?.children ?? []);

    const showNewEls = elements.filter(
      (el) => el.getAttribute("data-show-new-indicator") === "true",
    );

    const msgCounter = showNewEls.length;

    setIndicatorsState((prevState) => ({
      ...prevState,
      moreMsgsCounter: msgCounter,
    }));
  }, [containerRef.current, data]);

  return (
    <OploggMessageWrapper>
      {showHeader && (
        <Stack p={commonPadding}>
          <Clock />
        </Stack>
      )}

      {status === "pending" && (
        <>
          <SkeletonLoader skeletonType="oplogg" />
          <Divider />
        </>
      )}

      {status === "error" && <FailureMessage />}

      {status === "success" && allEntries && allEntries.length > 0 ? (
        <OploggList ref={containerRef} onScroll={onScroll} $readonly={readonly}>
          {allEntries.map((entry, index) => (
            <OploggElement
              key={getId(entry)}
              logEntry={entry}
              isLastEntry={index + 1 === allEntries.length}
              isHighlighted={getId(entry) === highlightedLogId}
              onClick={() => onHighlightItem(getId(entry))}
              userEmail={userEmail}
              readonly={readonly}
            />
          ))}

          {!readonly && (
            <>
              {hasNextPage && (
                <Button
                  variant="secondary"
                  size="sm"
                  isLoading={isFetchingNextPage}
                  style={{
                    margin: "18px auto",
                  }}
                  onClick={() => fetchNextPage()}
                >
                  Hent flere
                </Button>
              )}

              <Indicators className="indicators" placement="top">
                <NewIndicator onClick={goToTop}>
                  {indicatorsState.moreMsgsCounter}
                  &nbsp;
                  {indicatorsState.moreMsgsCounter > 1
                    ? "nye meldinger"
                    : "ny melding"}
                </NewIndicator>

                {bookmarkIsAbove && (
                  <BookmarkIndicator
                    icon={<ArrowUpFill24Icon />}
                    onClick={goToBookmark}
                  />
                )}
              </Indicators>

              <Indicators className="indicators" placement="bottom">
                {!bookmarkIsAbove && (
                  <BookmarkIndicator
                    icon={<ArrowDownFill24Icon />}
                    onClick={goToBookmark}
                  />
                )}
              </Indicators>
            </>
          )}
        </OploggList>
      ) : (
        <NoDataMessage reason="Fant ingen oplogg-meldinger" />
      )}
    </OploggMessageWrapper>
  );
};

export default withErrorBoundary(OploggMessages);
