import {
  Leg,
  Stop,
} from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/types";
import { StationEventResponse } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/stationEvents/stationEventResponseSchema";
import { InfraStructureInformation } from "shared/types/infrastructureResponse";
import { dropsRole } from "stores/dropsRole";
import { shallow } from "zustand/shallow";
import { createWithEqualityFn } from "zustand/traditional";

type EventState = {
  eventMode: boolean;
};

type EventAction = {
  setEventMode: (newState: EventState["eventMode"]) => void;
};

export const useEventMode = createWithEqualityFn<EventState & EventAction>(
  (set) => ({
    eventMode: false,
    setEventMode: (newState) => set(() => ({ eventMode: newState })),
  }),
);

type State = {
  clickedStops: Stop[];
  selectedLegs: Leg[];
  selectedStops: Stop[];
  allStopsSelected: boolean;
  stretchBuilderModalState:
    | {
        type: "infrastructure";
        stretchBuilderMode: boolean;
        eventInfo?: InfraStructureInformation;
      }
    | {
        type: "station";
        stretchBuilderMode: boolean;
        eventInfo?: StationEventResponse;
      }
    | {
        type: "closeOrEdit";
        uuids: { infrastructure: string[]; station: string[] };
      }
    | { type: "none" };
};

type Action = {
  setClickedStops: (newState: State["clickedStops"]) => void;
  setSelectedLegs: (newState: State["selectedLegs"]) => void;
  setSelectedStops: (newState: State["selectedStops"]) => void;
  setAllStopsSelected: (newState: State["allStopsSelected"]) => void;
  setStretchBuilderModalState: (
    newState: State["stretchBuilderModalState"],
  ) => void;
  closeAndResetStretchBuilder: () => void;
  isStretchBuilderMode: () => boolean;
};

export const useStretchBuilder = createWithEqualityFn<State & Action>(
  (set, get) => ({
    clickedStops: [],
    selectedLegs: [],
    selectedStops: [],
    allStopsSelected: false,
    stretchBuilderModalState: { type: "none" },
    setClickedStops: (newState) => set(() => ({ clickedStops: newState })),
    setSelectedLegs: (newState) => set(() => ({ selectedLegs: newState })),
    setSelectedStops: (newState) => set(() => ({ selectedStops: newState })),
    setAllStopsSelected: (newState) =>
      set(() => ({ allStopsSelected: newState })),
    setStretchBuilderModalState: (newState) =>
      set(() => ({
        stretchBuilderModalState: dropsRole.getState().isVaktleder()
          ? newState
          : { type: "none" },
      })),
    closeAndResetStretchBuilder: () => {
      useEventMode.getState().setEventMode(false);
      set(() => ({
        stretchBuilderModalState: { type: "none" },
        clickedStops: [],
        selectedLegs: [],
        selectedStops: [],
        allStopsSelected: false,
      }));
    },
    isStretchBuilderMode: () => {
      const state = get().stretchBuilderModalState;
      return (
        (state.type === "infrastructure" || state.type === "station") &&
        state.stretchBuilderMode
      );
    },
  }),
  shallow,
);
