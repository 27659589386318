import { compareDateStringAsc } from "shared/utils/datetime";
import { VehicleAlert } from "./schema";

export const sortFunction = (a: VehicleAlert, b: VehicleAlert) => {
  const getPriority = (alert: VehicleAlert) => {
    switch (alert.state) {
      case "ACTIVE":
        return 3;
      case "DELAYED":
      case "PENDING":
        return 2;
      default:
        return 1;
    }
  };

  const aPriority = getPriority(a);
  const bPriority = getPriority(b);

  return (
    bPriority - aPriority || compareDateStringAsc(a.createdAt, b.createdAt)
  );
};

export const alarmRowOnClick = (alert: VehicleAlert) => {
  window.open(`https://tilsyn.nsb.no/locations/${alert.setId}`, "_blank");
};

export const getMappedAlertType = (alert: VehicleAlert) => {
  switch (alert.type) {
    case "MAINS_TOO_LOW":
      return "For lav spenning";
    case "TEMP_TOO_LOW":
      return "For lav temperatur";
    default:
      return alert.type;
  }
};

export const getMappedAlertState = (alert: VehicleAlert) => {
  switch (alert.state) {
    case "PENDING":
    case "DELAYED":
      return "Avventer";
    case "ACTIVE":
      return "Aktiv";
    case "RESOLVE_IN_PROGRESS":
      return "Tiltak igangsatt";
    default:
      return alert.state;
  }
};
