import { getAbbreviationForRole } from "features/TodoOplogg/common/utils";
import React, { FC } from "react";
import { Text } from "@vygruppen/spor-react";
import { ROLE_TEXTS, UserRole } from "shared/types/roles";
import { dropsRole } from "stores/dropsRole";
import styled, { css } from "styled-components";
import UserCircleDashed from "shared/icons/user-circle-dashed.svg?react";
import UserCircleCheck from "shared/icons/user-circle-check.svg?react";

type ReadReceiptProps = {
  readReceipts: UserRole[] | undefined;
  entryId: number;
};

function makeReadReceiptText(
  readReceipts: ReadReceiptProps["readReceipts"],
  currentRole: UserRole,
) {
  if (!readReceipts?.length) {
    return "Ikke lest av noen";
  }

  if (readReceipts.length === ROLE_TEXTS.length) {
    // More semantic maybe to use Object.keys(UserRole).length
    // but at the moment of writing that has two extra roles
    return "Lest av alle";
  }

  // At least one read receipt, but not everyone
  const readReceiptsExceptCurrent = readReceipts.filter(
    (receipt) => receipt !== currentRole,
  );

  if (readReceiptsExceptCurrent.length === 0) {
    // Current role is the only receipt
    return "Lest";
  }

  const sortedReceipts = readReceiptsExceptCurrent.toSorted();
  const lastReceipt = sortedReceipts.pop()!; // Modifies the sortedReceipts array, removing the last element

  if (!sortedReceipts.length) {
    // LastReceipt is the only other receipt
    return `Lest av ${getAbbreviationForRole(lastReceipt)}`;
  }

  // More than one other read receipt to list
  return `Lest av ${sortedReceipts.map((role) => getAbbreviationForRole(role)).join(", ")} og ${getAbbreviationForRole(lastReceipt)}`;
}

const ReadReceiptIconContainer = styled.div<{ $readByCurrentRole: boolean }>`
  grid-area: 3 / 2;
  align-self: center;
  height: 24px;

  & * {
    opacity: 1;
  }

  ${({ $readByCurrentRole }) =>
    $readByCurrentRole
      ? css`
          & * {
            fill: ${({ theme }) => theme.colorSuccess};
          }
        `
      : css`
          cursor: pointer;

          & * {
            fill: ${({ theme }) => theme.colorTextSecondary};
          }

          &:hover * {
            fill: ${({ theme }) => theme.colorTextMain};
          }

          &:active * {
            fill: ${({ theme }) => theme.colorSuccess};
          }
        `}
`;

const ReadReceipts: FC<ReadReceiptProps> = ({ readReceipts, entryId }) => {
  const { role: currentRole } = dropsRole();

  const readByCurrentRole = !!readReceipts?.includes(currentRole);

  const onClick = (e: React.MouseEvent) => {
    e.preventDefault();

    if (!readByCurrentRole) {
      // TODO(afriestad): Submit backend mutation
      console.log(
        `Submitting readReceipt for ${currentRole} on entry ${entryId}`,
      );
    }
  };

  return (
    <>
      <ReadReceiptIconContainer
        $readByCurrentRole={readByCurrentRole}
        onClick={onClick}
      >
        {readReceipts?.length ? <UserCircleCheck /> : <UserCircleDashed />}
      </ReadReceiptIconContainer>
      <div
        style={{ gridArea: "3 / 3", alignSelf: "center", alignItems: "center" }}
      >
        <Text variant="xs">
          {makeReadReceiptText(readReceipts, currentRole)}
        </Text>
      </div>
    </>
  );
};

export { ReadReceipts };
