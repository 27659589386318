import { css } from "styled-components";
import { InfoMessageSeverity, SeverityColor } from "./severity";

/* Spacing between Severity Icon og summary/title */
export const COLUMN_GAP = 6;

/* Border radius of the info message components */
export const BORDER_RADIUS = 6;

export const InfoMessageBaseStyling = (
  severity: InfoMessageSeverity,
  borderColorOverride?: string,
) => css`
  padding: 9px;
  border-radius: ${BORDER_RADIUS}px;
  box-shadow: inset 0 0 0 2px ${borderColorOverride ?? SeverityColor(severity)};
  color: ${({ theme }) => theme.colorTextMain};
`;
