import { Thead, Tr } from "@chakra-ui/react";
import { useQuery } from "@tanstack/react-query";
import { Table, Tbody, Th } from "@vygruppen/spor-react";
import { getBackendUrl } from "api/common";
import { queryFnGET } from "api/tanStackQuery/helpers";
import { queryClient } from "api/tanStackQuery/queryClient";
import { withErrorBoundary } from "app/ErrorBoundry/ErrorBoundryDashboard";
import { TableFilter } from "features/CenterContent/RoleContent/Personellstyrer/DayPlanTrackings/TableFilter/TableFilter";
import React, { useEffect, useState } from "react";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import styled from "styled-components";
import { useDropsWebsocket } from "websocket/drops/DropsWebsocketContext";
import { TableSkeleton } from "shared/components/feedback/SkeletonLoader/TableSkeleton";
import { format } from "date-fns";
import { getLocalTimeZone } from "@internationalized/date";
import { TableContent } from "features/CenterContent/RoleContent/Personellstyrer/DayPlanTrackings/TableContent/TableContent";
import { DayPlanTrackingModal } from "features/CenterContent/RoleContent/Personellstyrer/DayPlanTrackings/DayPlanTrackingModal";
import {
  FilterType,
  initStates,
} from "features/CenterContent/RoleContent/Personellstyrer/DayPlanTrackings/TableFilter/constants";
import { DayPlanTrackingResponseType, DayPlanTrackingType } from "./types";

export const tableColumns = [
  "Status",
  "Selskap",
  "Sendt",
  "Starttid",
  "Mottaker",
  "Rolle",
  "Dagsverksnr",
  "GSM-R",
  "", // column for action buttons should have no title
];

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 12px;
  div {
    ${ObtrusiveScrollCss};
  }
`;

const DayPlanTrackings = () => {
  const [filterParams, setFilterParams] = useState<FilterType>(initStates);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [selectedDayPlanTracking, setSelectedDayPlanTracking] =
    useState<DayPlanTrackingType | null>();

  const { message } = useDropsWebsocket("Tjenestevarsling", [
    "DAY_PLAN_TRACKING",
  ]);

  const selectedDate = format(
    filterParams.chosenDate.toDate(getLocalTimeZone()),
    "yyyy-MM-dd",
  );

  const queryKey = ["dayPlanTrackings", selectedDate];

  const { isPending, isSuccess, isError, data } = useQuery({
    queryKey,
    refetchOnWindowFocus: true,
    queryFn: ({ signal }) =>
      queryFnGET<DayPlanTrackingResponseType>({
        signal,
        url: `${getBackendUrl()}/day-plan/trackings?fromDate=${selectedDate}&toDate=${selectedDate}`,
      }).then(
        (dayPlanTrackingResponse) => dayPlanTrackingResponse.dayPlanTrackings,
      ),
  });

  useEffect(() => {
    if (
      message.status === "received" &&
      message.data.topic === "DAY_PLAN_TRACKING"
    ) {
      const dayPlanTackingMessage = message.data.message;

      queryClient.setQueryData<DayPlanTrackingType[]>(queryKey, (prev) =>
        prev
          ? [
              dayPlanTackingMessage,
              ...prev.filter(
                (d) =>
                  !(
                    d.employeeId === dayPlanTackingMessage.employeeId &&
                    d.dayPlanDate === dayPlanTackingMessage.dayPlanDate
                  ),
              ),
            ]
          : [dayPlanTackingMessage],
      );
    }
  }, [message]);

  if (isError) {
    return <FailureMessage />;
  }

  return (
    <Container>
      <TableFilter
        setFilterParams={setFilterParams}
        filterParams={filterParams}
      />

      <Table
        variant="outline"
        size="sm"
        colorScheme="grey"
        overflow="scroll"
        display="flex"
        flexDirection="column"
      >
        <Thead position="sticky" top="0" zIndex="1" width="100%">
          <Tr>
            {tableColumns.map((columnTitle) => (
              <Th key={columnTitle}>{columnTitle}</Th>
            ))}
          </Tr>
        </Thead>
        <Tbody overflow="scroll">
          {isPending && (
            <TableSkeleton numOfRows={3} numOfColumns={tableColumns.length} />
          )}

          {isSuccess && (
            <TableContent
              data={data.filter(
                (dayPlanTracking) =>
                  dayPlanTracking.dayPlanDate === selectedDate,
              )}
              filterParams={filterParams}
              onRowClick={(dayPlanTracking: DayPlanTrackingType) => {
                setSelectedDayPlanTracking(dayPlanTracking);
                setModalOpen(true);
              }}
            />
          )}
        </Tbody>
      </Table>

      {isSuccess && selectedDayPlanTracking && (
        <DayPlanTrackingModal
          showModal={modalOpen}
          closeModal={() => setModalOpen(false)}
          dayPlanTracking={selectedDayPlanTracking}
        />
      )}
    </Container>
  );
};

export default withErrorBoundary(DayPlanTrackings);
