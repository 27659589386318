import { Text } from "@vygruppen/spor-react";
import { TrainInformationAggregated } from "features/CenterContent/VehicleDetails/TrainDetails/TrainCondition/OperationalTrainInfo/types/trainInformationAggregated";
import { FC } from "react";
import { formatDayDateString } from "shared/utils/datetime";
import { useTheme } from "styled-components";

type TrainInfoVersionsProps = {
  trainInfo: TrainInformationAggregated;
};

export const TrainInfoVersions: FC<TrainInfoVersionsProps> = ({
  trainInfo,
}) => {
  const theme = useTheme();

  return (
    <>
      {trainInfo.versions.map((version) => (
        <li key={version.updatedAt}>
          <Text variant="xs">{version.message}</Text>
          <Text variant="xs" textColor={theme.colorTextSecondary}>
            {formatDayDateString(version.updatedAt)}
          </Text>
        </li>
      ))}
    </>
  );
};
