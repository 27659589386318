export const appPalette = {
  wine: "#99002E",
  raspberry: "#CC003D",
  rose: "#FF3370",
  operations: "#383E42",
  blossom: "#FFCCDB",
  whiteSmoke: "#F7F7F7",
  whiteSnow: "#FDFDFD",
  darkGloomyShade: "#212121",
  iconHighlight: "#00685E",
  watermelon: "#FF99B8",
  dimmedDarkGrey: "#414245",
  yellow800: "#503D0A",
  yellow700: "#84620A",
  blue900: "#0C2033",
  orange700: "#84441F",
  darkerGrey: "#1C1C1D", // Based on grey 900
};
