import styled from "styled-components";
import { ObtrusiveScrollCss } from "shared/components/transitions/scroll";
import { FC } from "react";
import { useQuery } from "@tanstack/react-query";
import { getBackendUrl } from "api/common";
import {
  HStack,
  Modal,
  ModalBody as SporModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  SkeletonCircle,
  SkeletonText,
  VStack,
  Divider,
} from "@vygruppen/spor-react";
import { FailureMessage } from "shared/components/feedback/FailureMessage/FailureMessage";
import { UpcomingEvents } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/UpcomingEventsModal/UpcomingEvents";
import { axiosClient } from "api/http/config";
import {
  InfraStructureInformation,
  upcomingEventsResponseSchema,
} from "shared/types/infrastructureResponse";

const ModalBody = styled(SporModalBody)`
  ${ObtrusiveScrollCss};
  overflow-y: auto;
  display: grid;
`;

type UpcomingEventsModalProps = {
  onClose: () => void;
};

export const UpcomingEventsModal: FC<UpcomingEventsModalProps> = ({
  onClose,
}) => {
  const { data, status } = useQuery({
    queryKey: ["upcomingInfraStructureEvents"],
    staleTime: 0,
    queryFn: ({ signal }) =>
      axiosClient
        .get<InfraStructureInformation[]>(
          `${getBackendUrl()}/infrastructure-event/upcoming?countryCode=NO`,
          {
            signal,
          },
        )
        .then((res) => upcomingEventsResponseSchema.parse(res.data)),
  });

  return (
    <Modal isOpen onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent maxH="80vh">
        <ModalHeader>Fremtidige hendelser</ModalHeader>
        <ModalCloseButton />
        <Divider />
        <ModalBody gap={2}>
          {status === "pending" && (
            <VStack gap={1} w="100%">
              {Array.from(Array(3)).map((_, i) => (
                <HStack
                  key={i}
                  p={3}
                  w="100%"
                  justify="space-between"
                  alignItems="flex-top"
                  h="100px"
                >
                  <SkeletonText noOfLines={4} w="70%" />
                  <SkeletonCircle />
                </HStack>
              ))}
            </VStack>
          )}
          {status === "error" && <FailureMessage />}
          {status === "success" && <UpcomingEvents data={data} />}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
