import { CalendarDateTime } from "@internationalized/date";
import { InfrastructureProviderEnum } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/InfrastructureProvider";
import { WeekRepeat } from "features/CenterContent/RoleContent/TrainMap/StretchBuilder/infrastructureEvents/infrastructureEventModal/durationInputs/PeriodicDuration";
import {
  optionalInputWithDescription,
  reasonInput,
} from "features/CenterContent/shared/operationalInformation/utils";
import { z } from "zod";

const calendarDateTimeSchema = z.custom<CalendarDateTime>();

const openEndedDurationSchema = z.object({
  type: z.literal("OPEN_ENDED"),
  from_time: calendarDateTimeSchema,
  extension_time_in_minutes: z.string().refine((val) => Number(val) > 0),
});

const timedDurationSchema = z.object({
  type: z.literal("TIMED"),
  from_time: calendarDateTimeSchema,
  to_time: calendarDateTimeSchema,
});

const ruleSchema = z.object({
  id: z.string(),
  startDateTime: calendarDateTimeSchema,
  endDateTime: calendarDateTimeSchema,
  days: z.array(z.number()),
  repeat: z.nativeEnum(WeekRepeat),
});

const periodicDurationSchema = z.object({
  type: z.literal("PERIODIC"),
  rules: z.array(ruleSchema).min(1),
});

const formSchemaDuration = z.discriminatedUnion("type", [
  openEndedDurationSchema,
  timedDurationSchema,
  periodicDurationSchema,
]);

export const infrastructureParametersSchema = z.object({
  event: z
    .string()
    .min(1, "Du må velge type hendelse for å kunne opprette hendelsen"),
  reason: reasonInput,
  action: optionalInputWithDescription,
  actionCard: z.string(),
  consequence: optionalInputWithDescription,
  stretchName: z.string().min(1),
  affectedLegs: z
    .array(
      z.object({
        fromStop: z.string(),
        toStop: z.string(),
      }),
    )
    .min(1),
  redirectStretches: z.array(
    z.object({
      name: z.string(),
      legs: z.array(z.object({ fromStop: z.string(), toStop: z.string() })),
    }),
  ),
  durations: formSchemaDuration,
  isirkId: z.string().nullable(),
  prognosisType: optionalInputWithDescription,
  prognosisTime: calendarDateTimeSchema,
  infrastructureProvider: z.nativeEnum(InfrastructureProviderEnum),
});

export const formSchema = z.object({
  infrastructureForm: infrastructureParametersSchema,
});

export type FormSchema = z.infer<typeof formSchema>;

export type Rule = z.infer<typeof ruleSchema>;

export type OpenEndedFormDuration = z.infer<typeof openEndedDurationSchema>;

export type TimedFormDuration = z.infer<typeof timedDurationSchema>;

export type PeriodicFormDuration = z.infer<typeof periodicDurationSchema>;

export type FormSchemaDuration = z.infer<typeof formSchemaDuration>;
