import React from "react";
import styled from "styled-components";
import {
  Checkbox,
  DatePicker,
  FormControl,
  HStack,
  InfoSelect,
  Item,
} from "@vygruppen/spor-react";
import {
  COMPANYCODE,
  ROLE,
  FilterType,
} from "features/CenterContent/RoleContent/Personellstyrer/DayPlanTrackings/TableFilter/constants";

interface TableFilterProps {
  setFilterParams: (update: (prev: FilterType) => FilterType) => void;
  filterParams: FilterType;
}

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
  flex-wrap: wrap;
`;

const filterRole = [
  { label: "Lok", key: ROLE.LOK },
  { label: "Kond", key: ROLE.KOND },
  { label: "Lok / Kond", key: ROLE.BOTH },
  { label: "Ingen rolle", key: ROLE.NONE },
];

const filterCompanyId = [
  { label: COMPANYCODE.VY, key: COMPANYCODE.VY },
  { label: COMPANYCODE.VYG, key: COMPANYCODE.VYG },
  { label: COMPANYCODE.VYT, key: COMPANYCODE.VYT },
  { label: COMPANYCODE.VYT_VYG_VY, key: COMPANYCODE.VYT_VYG_VY },
];

const TableFilter = ({ setFilterParams, filterParams }: TableFilterProps) => (
  <Container>
    <InfoSelect
      label=""
      aria-label="Rolle"
      items={filterRole}
      value={filterParams.role}
      onChange={(value) => {
        setFilterParams((prev) => ({ ...prev, role: value.toString() }));
      }}
    >
      {(item) => <Item>{item.label}</Item>}
    </InfoSelect>
    <InfoSelect
      label=""
      aria-label="Selskapskode"
      items={filterCompanyId}
      value={filterParams.companyCode}
      onChange={(value) => {
        setFilterParams((prev) => ({
          ...prev,
          companyCode: value.toString(),
        }));
      }}
    >
      {(item) => <Item>{item.label}</Item>}
    </InfoSelect>

    <HStack>
      <Checkbox
        isChecked={filterParams.showClosed}
        onChange={(event) => {
          setFilterParams((prev) => ({
            ...prev,
            showClosed: event.target.checked,
          }));
        }}
      >
        Vis lukkede
      </Checkbox>
      <Checkbox
        isChecked={filterParams.showFuture}
        onChange={(event) => {
          setFilterParams((prev) => ({
            ...prev,
            showFuture: event.target.checked,
          }));
        }}
      >
        Vis frem i tid
      </Checkbox>
      <Checkbox
        isChecked={filterParams.showDeleted}
        onChange={(event) => {
          setFilterParams((prev) => ({
            ...prev,
            showDeleted: event.target.checked,
          }));
        }}
      >
        Vis slettede
      </Checkbox>
    </HStack>

    <FormControl maxWidth="max-content">
      <DatePicker
        variant="base"
        label="Dato"
        value={filterParams.chosenDate}
        onChange={(date) => {
          if (date !== null)
            setFilterParams((prev) => ({ ...prev, chosenDate: date }));
        }}
      />
    </FormControl>
  </Container>
);

export { TableFilter };
