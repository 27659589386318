import { differenceInMinutes } from "date-fns";
import { DefaultTheme } from "styled-components";
import { DropsLogEntry, DropsLogResponse } from "./types";

const OPLOGG_NEW_TRESHOLD = 6;
const OPLOGG_OLD_TRESHOLD = 8;

export type DropsLogAge = "NEW" | "ALMOST_NEW" | "OLD";
const getAgeInMinutes = (reportedTime: Date): number => {
  const now = new Date();
  return differenceInMinutes(now, reportedTime);
};

export const getAge = (reportedTime: Date): DropsLogAge => {
  const ageInMinutes = getAgeInMinutes(reportedTime);

  if (ageInMinutes <= OPLOGG_NEW_TRESHOLD) {
    return "NEW";
  }

  if (ageInMinutes <= OPLOGG_OLD_TRESHOLD) {
    return "ALMOST_NEW";
  }

  return "OLD";
};
export const getAgeColor = (age: DropsLogAge, theme: DefaultTheme): string => {
  switch (age) {
    case "ALMOST_NEW":
      return theme.colorAlarmSecondary;
    case "NEW":
      return theme.colorAlarm;
    case "OLD":
    default:
      return theme.colorSeparationLine;
  }
};

export const getId = (dropsLogEntry: DropsLogEntry) =>
  dropsLogEntry.uuid ?? `${dropsLogEntry.id}`; // TODO: DynamoDB - Remove this after dynamoDB is removed

export const getDropsLogPath = (useDropsLogV2: boolean) =>
  useDropsLogV2 ? "drops-log-v2" : "drops-log"; // TODO: DynamoDB - Remove this after dynamoDB is removed

const alreadyInPages = (pages: DropsLogResponse[], newMessage: DropsLogEntry) =>
  pages.some((page) =>
    page.entries.some((entry) => getId(entry) === getId(newMessage)),
  );

export const updatePages = (
  pages: DropsLogResponse[],
  newMessage: DropsLogEntry,
  useDropsLogV2: boolean,
) =>
  pages.map((page, index) => ({
    ...page,
    lastSeen:
      page.lastSeen +
      (useDropsLogV2 && !alreadyInPages(pages, newMessage) ? 1 : 0), // DropsLogV2: Increment pageOffset if a new message is added
    entries: [
      ...(index === 0 ? [newMessage] : []),
      ...page.entries.filter((entry) => getId(entry) !== getId(newMessage)),
    ],
  }));
